/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionView_auction$key } from "src/types/__generated__/AuctionView_auction.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";

import { PageContext } from "src/app/context/page";
import { useIntersectionObserver } from "src/hooks";
import {
  SBActivityIndicator,
  SBMarkdownRenderer,
  SBPageHeader,
} from "src/sbxui";

import AuctionItem from "./AuctionItem";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AuctionView_auction$key;
}>;

const AuctionView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();
  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment(
    graphql`
      fragment AuctionView_auction on Auction
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 12 }
        cursor: { type: "String" }
        orderBy: {
          type: "AuctionLotOrderByInput"
          defaultValue: { closesAt: ASC }
        }
        searchTerm: { type: "String", defaultValue: "" }
      )
      @refetchable(queryName: "AuctionViewPaginationQuery") {
        title
        description
        tagline
        lots(
          first: $count
          after: $cursor
          orderBy: $orderBy
          where: { searchTerm: $searchTerm }
        ) @connection(key: "AuctionLotsView_lots") {
          edges {
            node {
              id
              ...AuctionItem_auctionLot
            }
          }
        }
      }
    `,
    queryKey,
  );

  const title = data.title;
  const description = data.description;
  const tagline = data.tagline;
  const pageContext = useContext(PageContext);

  const lots = data.lots?.edges ?? [];

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(10);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  useEffect(() => {
    pageContext?.setTitle(
      t("auctions.auction.document-title", {
        title,
      }),
    );
  }, [title, pageContext, t]);

  return (
    <div {...stylex.props(styles.auction)}>
      <div {...stylex.props(styles.header)}>
        <div {...stylex.props(styles.auctionHeader)}>
          <div>
            <SBPageHeader
              title={t("auctions.auction.page-title", {
                title,
              })}
            />
          </div>
          <div>
            <SBMarkdownRenderer markdown={tagline ?? ""} />
          </div>
        </div>
        <div {...stylex.props(styles.auctionHeaderDescription)}>
          <SBMarkdownRenderer markdown={description} />
        </div>
      </div>
      <ul {...stylex.props(styles.lots)}>
        {lots.map(({ node }) => (
          <AuctionItem key={node.id} queryKey={node} />
        ))}
      </ul>
      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </div>
  );
};

const styles = stylex.create({
  auction: {
    marginBottom: 96,
  },
  auctionHeader: {
    margin: 20,
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: "50%",
    },
  },
  auctionHeaderDescription: {
    margin: 20,
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: "50%",
    },
  },
  header: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "flex",
      default: "flex",
    },
    margin: 20,
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
  lots: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(3, 1fr)",
      default: "repeat(4, 1fr)",
    },
    margin: 0,
    marginBottom: 64,
    padding: 0,
  },
});

export default AuctionView;
