/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionLotHeader_product$key } from "src/types/__generated__/AuctionLotHeader_product.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import {
  ButtonType,
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBFmvBadge,
  SBHeading,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney, formatTitle } from "src/utils";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

interface AuctionLotUrlOptions {
  auctionLotId: string | undefined;
  imageUrl: string | null;
  title: string;
}

const formatAuctionLotDetailUrl = ({
  auctionLotId,
  imageUrl,
  title,
}: AuctionLotUrlOptions) =>
  auctionLotId == null
    ? "https://shortboxed.com/auctions/"
    : `https://shortboxed.page.link/?link=https://shortboxed.com/auction-lot/${encodeURIComponent(auctionLotId)}&st=${encodeURIComponent(title)}&apn=com.shortboxed&ibi=com.shortboxed&isi=1497091986${imageUrl == null ? "" : `&si=${encodeURIComponent(imageUrl)}`}`;

type Props = Readonly<{
  queryKey: AuctionLotHeader_product$key;
}>;

const AuctionLotHeader = ({ queryKey }: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment AuctionLotHeader_product on Product {
        id
        comicDetails {
          title
          number
        }
        images {
          edges {
            node {
              url(quality: 100, webp: true, width: 1000)
            }
          }
        }
        auctionLot {
          id
          isClosed
          currentBidAmount
          currentBidCurrency
        }
        fmvScore {
          minValue
          maxValue
          currency
          rank
        }
      }
    `,
    queryKey,
  );

  const { comicDetails, images, auctionLot, fmvScore } = data;
  const { title, number } = comicDetails ?? {};
  const {
    currentBidAmount,
    currentBidCurrency,
    id: auctionLotId,
    isClosed,
  } = auctionLot ?? {};

  const imageEdges = images?.edges ?? [];
  const imageUrl = imageEdges.map(({ node }) => node.url)[0];

  const fmvMinValue = fmvScore?.minValue ?? 0;
  const fmvMaxValue = fmvScore?.maxValue ?? 0;
  const fmvCurrency = fmvScore?.currency ?? "USD";
  const fmvRank = fmvScore?.rank;

  const handleClickViewInApp = (event: React.SyntheticEvent) => {
    event.preventDefault();
    window.open(
      formatAuctionLotDetailUrl({
        auctionLotId,
        imageUrl,
        title: productTitle,
      }),
      "_blank",
      "noopener,noreferrer",
    );
  };

  const productTitle = formatTitle(t, title, number);

  return (
    <>
      <SBHeading level={HeadingLevel.H1} style={styles.productTitle}>
        {productTitle}
      </SBHeading>
      <div {...stylex.props(auto, styles.container)}>
        <div {...stylex.props(styles.columnLeft)}>
          {currentBidAmount != null && currentBidCurrency != null && (
            <>
              <SBParagraph style={styles.askingPrice}>
                {t(
                  `auction.lot.actions.${isClosed ? "winning" : "current"}-bid`,
                )}
              </SBParagraph>
              <SBParagraph style={styles.productCost}>
                {currentBidAmount > 0
                  ? formatMoney(
                      currentBidAmount,
                      currentBidCurrency,
                      i18n.language,
                    )
                  : t("auction.lot.no-bids")}
              </SBParagraph>
            </>
          )}
          {fmvMinValue > 0 && fmvMaxValue > 0 && (
            <SBParagraph style={styles.fmvRange}>
              {t("product.fmv-range", {
                max: formatMoney(fmvMaxValue, fmvCurrency, i18n.language),
                min: formatMoney(fmvMinValue, fmvCurrency, i18n.language),
              })}
            </SBParagraph>
          )}
          <SBFmvBadge fmvRank={fmvRank} style={styles.fmvRank} />
        </div>
        <div {...stylex.props(styles.columnRight)}>
          <SBParagraph style={styles.inApp}>
            {t("auction.lot.in-app")}
          </SBParagraph>
          <SBButton
            block={true}
            title={t("auction.lot.buttons.in-app")}
            type={ButtonType.Button}
            variation={ButtonVariation.Default}
            onClick={handleClickViewInApp}
          />
        </div>
      </div>
    </>
  );
};

const styles = stylex.create({
  askingPrice: {
    fontSize: 12,
    margin: 0,
  },
  columnLeft: {
    alignSelf: "flex-start",
  },
  columnRight: {
    alignSelf: "flex-end",
  },
  container: {
    borderColor: colors.tableBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    marginBlock: 24,
    padding: 16,
  },
  fmvRange: {
    fontSize: 12,
    margin: 0,
  },
  fmvRank: {
    marginTop: 8,
  },
  inApp: {
    fontSize: 14,
    marginBottom: 16,
  },
  productCost: {
    color: colors.color,
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 48,
    lineHeight: 0.875,
    marginBlock: 8,
    textTransform: "uppercase",
  },
  productTitle: {
    fontSize: {
      [MOBILE]: 48,
      [TABLET]: 72,
      default: 96,
    },
  },
});

export default AuctionLotHeader;
