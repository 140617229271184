/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LoginForm from "src/app/components/login/LoginForm";
import { PageContext } from "src/app/context/page";
import { HOME_PATH } from "src/app/router/Router";
import { SBBackButton } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const Signup = (): React.ReactNode => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageContext = useContext(PageContext);

  const handleClickBack = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (document.referrer.indexOf(window.location.host) !== -1) {
      history.go(-1);
    } else {
      navigate(HOME_PATH);
    }
  };

  useEffect(() => {
    pageContext?.setTitle(t("login.document-title"));
  }, [t, pageContext]);

  return (
    <div {...stylex.props(auto, styles.root)}>
      <div {...stylex.props(auto, styles.form)}>
        <SBBackButton
          label={t("common.buttons.back")}
          style={styles.backButton}
          onClick={handleClickBack}
        />
        <LoginForm
          onLoginStart={() => {
            return;
          }}
          onLoginSuccess={() => navigate(HOME_PATH)}
        />
      </div>
    </div>
  );
};

const styles = stylex.create({
  backButton: {
    left: 32,
    position: "absolute",
    top: 32,
  },
  form: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  root: {
    backgroundColor: colors.headlessImageBackgroundColor,
    display: {
      [MOBILE]: "grid",
      [TABLET]: "grid",
      default: "flex",
    },
    gridTemplateColumns: "repeat(1, 1fr)",
    height: "100vh",
    justifyContent: "center",
  },
});

export default React.memo(Signup);
