/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useState } from "react";

import { SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<
  React.PropsWithChildren<{
    checked: boolean;
    disabled?: boolean;
    id: string;
    indeterminate?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    style?: StyleXStyles;
  }>
>;

export const SBCheckboxInput = ({
  checked,
  children,
  disabled = false,
  id,
  indeterminate = false,
  onChange,
  style,
}: Props): React.ReactNode => {
  const [isFocused, setIsFocused] = useState(false);

  let icon = indeterminate
    ? "indeterminate_check_box"
    : "check_box_outline_blank";
  if (checked) {
    icon = "check_box";
  }

  const handleFocus = (_event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
  };

  const handleBlur = (_event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
  };

  return (
    <label
      {...stylex.props(
        auto,
        styles.label,
        disabled && styles.labelDisabled,
        isFocused && styles.labelFocused,
        style,
      )}
      htmlFor={id}
    >
      <input
        {...stylex.props(styles.checkbox)}
        checked={checked}
        disabled={disabled}
        id={id}
        type="checkbox"
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
      />
      <SBIcon fill={false} icon={icon} style={styles.icon} />
      <div {...stylex.props(auto, styles.children)}>{children}</div>
    </label>
  );
};

const styles = stylex.create({
  checkbox: {
    display: "block",
    height: 0,
    overflow: "hidden",
    width: 0,
  },
  children: {
    color: colors.color,
    marginTop: 4,
  },
  icon: {
    color: colors.colorMuted,
    marginRight: 8,
  },
  label: {
    alignItems: "flex-start",
    borderRadius: 2,
    display: "flex",
    justifyContent: "flex-start",
  },
  labelDisabled: {
    opacity: 0.4,
  },
  labelFocused: {
    outline: colors.outline,
  },
});
