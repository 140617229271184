/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Auth from "src/app/components/auth/Auth";
import TopNavigationDesktop from "src/app/components/navigation/TopNavigationDesktop";
import TopNavigationMobile from "src/app/components/navigation/TopNavigationMobile";
import { SHORTBOXED } from "src/app/constants";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const Header = (): React.ReactNode => {
  const { t } = useTranslation();

  return (
    <>
      <div {...stylex.props(auto, styles.desktopRoot)}>
        <a {...stylex.props(styles.desktopSkip)} href="#main">
          {t("home.skip-link")}
        </a>
        <div {...stylex.props(styles.desktopInner)}>
          <div {...stylex.props(auto, styles.desktopLogo)}>
            <Link
              {...stylex.props(auto, styles.desktopImage)}
              aria-label={t("home.home-link")}
              to="/"
            >
              {SHORTBOXED}
            </Link>
          </div>
          <div {...stylex.props(styles.desktopNav)}>
            <TopNavigationDesktop />
          </div>
          <div {...stylex.props(styles.desktopAuth)}>
            {process.env.REACT_APP_USE_AUTH === "true" && <Auth />}
          </div>
        </div>
      </div>
      <div {...stylex.props(auto, styles.mobileRoot)}>
        <a {...stylex.props(styles.mobileSkip)} href="#main">
          {t("home.skip-link")}
        </a>
        <div {...stylex.props(styles.mobileInner)}>
          <div {...stylex.props(styles.mobileTop)}>
            <div {...stylex.props(styles.mobileRightBar)}>
              <TopNavigationMobile />
              <div {...stylex.props(auto, styles.mobileLogo)}>
                <Link
                  {...stylex.props(auto, styles.mobileImage)}
                  aria-label={t("home.home-link")}
                  to="/"
                >
                  {SHORTBOXED}
                </Link>
              </div>
            </div>

            <div {...stylex.props(styles.mobileLeftBar)}>
              {process.env.REACT_APP_USE_AUTH === "true" && (
                <div {...stylex.props(styles.mobileAuth)}>
                  <Auth />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = stylex.create({
  desktopAuth: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "flex-end",
    width: 144,
  },
  desktopImage: {
    backgroundImage: colors.topNavigationLogo,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderRadius: 8,
    display: "block",
    height: 28,
    outline: {
      ":focus-visible": colors.outline,
    },
    overflow: "hidden",
    textIndent: -99999,
    width: 144,
  },
  desktopInner: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginInline: "auto",
    paddingBlock: 24,
    width: 1200,
  },
  desktopLogo: {
    width: 144,
  },
  desktopNav: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: "center",
    marginInline: 80,
  },
  desktopRoot: {
    backgroundColor: colors.topNavigationBackgroundColor,
    boxShadow: colors.topNavigationShadow,
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "block",
    },
  },
  desktopSkip: {
    height: 0,
    left: -99999,
    overflow: "hidden",
    position: "absolute",
    top: -99999,
    width: 0,
  },
  mobileAuth: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  mobileImage: {
    backgroundImage: colors.topNavigationLogo,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderRadius: 8,
    display: "block",
    height: 28,
    outline: {
      ":focus-visible": colors.outline,
    },
    overflow: "hidden",
    textIndent: -99999,
    width: 144,
  },
  mobileInner: {
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginInline: "auto",
    paddingBlock: 8,
    width: "95%",
  },
  mobileLeftBar: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
  },
  mobileLogo: {
    marginTop: 4,
    width: 144,
  },
  mobileRightBar: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  mobileRoot: {
    backgroundColor: colors.topNavigationBackgroundColor,
    boxShadow: colors.topNavigationShadow,
    display: {
      [MOBILE]: "block",
      [TABLET]: "block",
      default: "none",
    },
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  mobileSkip: {
    height: 0,
    left: -99999,
    overflow: "hidden",
    position: "absolute",
    top: -99999,
    width: 0,
  },
  mobileTop: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
});

export default Header;
