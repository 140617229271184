/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import { DEFAULT_SORT, Sort } from "src/app/context/vertical-search";

export const getContextFromShopParams = (): {
  sortParam: Sort;
  termParam: string;
} => {
  const urlParams = new URLSearchParams(window.location.search);
  const termParam = urlParams.get("term") ?? "";
  const sortParamString = urlParams.get("sort") ?? "createdAt-desc";

  let sortParam = DEFAULT_SORT;
  if (Object.values(Sort).some((sort: string) => sort === sortParamString)) {
    sortParam = sortParamString as Sort;
  }

  return {
    sortParam,
    termParam,
  };
};
