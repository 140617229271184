/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  ACCOUNT_ADDRESSES_PATH,
  ACCOUNT_FAVORITES_PATH,
  ACCOUNT_INVOICES_PATH,
  ACCOUNT_OFFERS_PATH,
  ACCOUNT_ORDER_DETAILS_PATH,
  ACCOUNT_ORDERS_PATH,
  ACCOUNT_PASSWORD_PATH,
  ACCOUNT_PATH,
} from "src/app/router/Router";
import { SBSideNav } from "src/sbxui";

const AccountSideNav = (): React.ReactNode => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navItems: {
    id: string;
    label: string;
    path: string;
    selected: boolean;
  }[] = [
    {
      id: "PERSONAL_INFORMATION",
      label: t(`account.nav.personal-information`),
      path: ACCOUNT_PATH,
      selected: pathname === ACCOUNT_PATH,
    },
    {
      id: "PASSWORD",
      label: t(`account.nav.password`),
      path: ACCOUNT_PASSWORD_PATH,
      selected: pathname === ACCOUNT_PASSWORD_PATH,
    },
    {
      id: "ADDRESSES",
      label: t(`account.nav.addresses`),
      path: ACCOUNT_ADDRESSES_PATH,
      selected: pathname === ACCOUNT_ADDRESSES_PATH,
    },
    {
      id: "ORDERS",
      label: t(`account.nav.orders`),
      path: ACCOUNT_ORDERS_PATH,
      selected:
        pathname === ACCOUNT_ORDERS_PATH ||
        pathname.startsWith(ACCOUNT_ORDER_DETAILS_PATH.replace(":id", "")),
    },
    {
      id: "INVOICES",
      label: t(`account.nav.invoices`),
      path: ACCOUNT_INVOICES_PATH,
      selected:
        pathname === ACCOUNT_INVOICES_PATH ||
        pathname.startsWith(ACCOUNT_INVOICES_PATH.replace(":id", "")),
    },
    {
      id: "FAVORITES",
      label: t(`account.nav.favorites`),
      path: ACCOUNT_FAVORITES_PATH,
      selected: pathname === ACCOUNT_FAVORITES_PATH,
    },
    {
      id: "OFFERS",
      label: t(`account.nav.offers`),
      path: ACCOUNT_OFFERS_PATH,
      selected: pathname === ACCOUNT_OFFERS_PATH,
    },
  ];

  const handleOptionChange = (selected: string) => {
    const selectedOption =
      navItems.find(({ label }) => label === selected) ?? null;

    if (selectedOption) {
      navigate(selectedOption.path);
    }
  };

  return <SBSideNav items={navItems} onChange={handleOptionChange} />;
};

export default AccountSideNav;
