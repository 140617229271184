/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { auto } from "src/themes";

type Props = Readonly<{
  fill?: boolean;
  // Remember to add new icons to "icon-alt" in translation.json
  icon: string;
  size?: 20 | 24 | 40 | 48;
  style?: StyleXStyles;
}>;

export const SBIcon = React.memo(
  ({
    icon,
    fill = true,
    size = 24,
    style,
    ...rest
  }: Props): React.ReactNode => {
    const { t } = useTranslation();

    const fillValue = fill ? 1 : 0;

    return (
      <span
        {...stylex.props(
          auto,
          styles.icon,
          styles.size(size, fillValue),
          style,
        )}
        aria-label={t(`icon-alt.${kebabCase(icon)}`)}
        role="img"
        {...rest}
      >
        {icon}
      </span>
    );
  },
);

const styles = stylex.create({
  icon: {
    MozOsxFontSmoothing: "grayscale",
    WebkitFontSmoothing: "antialiased",
    // Define obviously wrong colors by default so we immediately catch a
    // mistake where a color wasn't provided.
    color: "lime",
    direction: "ltr",
    display: "inline-block",
    fontFamily: "Material Symbols Outlined, sans-serif",
    fontFeatureSettings: "liga",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "normal",
    lineHeight: 1,
    textRendering: "optimizeLegibility",
    textTransform: "none",
    whiteSpace: "nowrap",
    wordWrap: "normal",
  },
  size: (size: number, fillValue: number) => ({
    fontSize: size,
    fontVariationSettings: `'FILL' ${fillValue}, 'wght' 400, 'GRAD' 0, 'opsz' ${size}`,
  }),
});
