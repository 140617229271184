/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountInvoicesView_user$key } from "src/types/__generated__/AccountInvoicesView_user.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { HeadingLevel, SBHeading, SBTabs } from "src/sbxui";

import AccountInvoiceCard from "./AccountInvoiceCard";

type Props = Readonly<{
  queryKey: AccountInvoicesView_user$key;
}>;

const AccountInvoicesView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>("ACTIVE");

  const user = useFragment(
    graphql`
      fragment AccountInvoicesView_user on User {
        activeInvoices: invoices(
          first: 20
          where: { status: [OPEN, TEMP, PROCESSING, REQUIRES_ACTION] }
          orderBy: { createdAt: DESC }
        ) {
          edges {
            node {
              id
              ...AccountInvoiceCard_invoice
            }
          }
        }
        closedInvoices: invoices(
          first: 20
          where: { status: [EXPIRED, CLOSED, PAID] }
          orderBy: { createdAt: DESC }
        ) {
          edges {
            node {
              id
              ...AccountInvoiceCard_invoice
            }
          }
        }
      }
    `,
    queryKey,
  );

  const getTabBody = (): React.ReactNode => {
    switch (selectedTab) {
      case "CLOSED":
        return (
          <div>
            {user?.closedInvoices?.edges?.map(({ node }) => (
              <AccountInvoiceCard key={node.id} queryKey={node} />
            ))}
          </div>
        );
      case "ACTIVE":
      default:
        return (
          <div>
            {user?.activeInvoices?.edges?.map(({ node }) => (
              <AccountInvoiceCard key={node.id} queryKey={node} />
            ))}
          </div>
        );
    }
  };

  const TABS = [
    { id: "ACTIVE", name: "Active", selected: selectedTab === "ACTIVE" },
    { id: "CLOSED", name: "Closed", selected: selectedTab === "CLOSED" },
  ];

  return (
    <>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("account.nav.invoices")}
      </SBHeading>
      <SBTabs tabs={TABS} onTabSelected={(tabId) => setSelectedTab(tabId)} />
      {getTabBody()}
    </>
  );
};

const styles = stylex.create({
  heading: {
    fontSize: 24,
    marginBottom: 16,
  },
});

export default AccountInvoicesView;
