/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductDetailMeta_product$key } from "src/types/__generated__/ProductDetailMeta_product.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { SELLER_PATH } from "src/app/router/Router";
import { SBLink, SBProductDetailMeta } from "src/sbxui";
import { auto } from "src/themes";
import { formatShopName } from "src/utils/_utils/formatShopName";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: ProductDetailMeta_product$key;
}>;

const ProductDetailMeta = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ProductDetailMeta_product on Product {
        submissionNotes
        comicDetails {
          grade
          gradingAuthority
          year
          publisher
          keyComments
          artComments
          barcode
          categoryRollup
          gradeText
          publicGraderNotes
          pedigree
          variant
          pageQuality
          signatures
          specialCopy
        }
        gradedCopy {
          slabDefects
        }
        seller {
          shopName
          shopUrl
        }
      }
    `,
    queryKey,
  );

  const { comicDetails, gradedCopy, submissionNotes, seller } = data;

  const {
    grade,
    gradingAuthority,
    year,
    publisher,
    keyComments,
    artComments,
    barcode,
    categoryRollup,
    gradeText,
    publicGraderNotes,
    pedigree,
    variant,
    pageQuality,
    signatures,
    specialCopy,
  } = comicDetails ?? {};

  const { slabDefects } = gradedCopy;
  const gradeValue = grade === 0 ? t("raw-grades.0-0") : grade?.toFixed(1);

  const { shopName, shopUrl } = seller ?? {};
  const formattedShopName = formatShopName(shopName, shopUrl);

  const items = [
    formattedShopName
      ? {
          translationKey: "product.seller-shopName",
          value: shopUrl ? (
            <SBLink to={SELLER_PATH.replace(":shopUrl", shopUrl ?? "")}>
              {formattedShopName}
            </SBLink>
          ) : (
            formattedShopName
          ),
        }
      : {},
    {
      translationKey: "product.publisher-year",
      value: `${publisher} (${year})`,
    },
    variant
      ? {
          translationKey: "product.variant",
          value: variant,
        }
      : {},
    {
      args: { gradingAuthority },
      translationKey: "product.grade",
      value:
        gradingAuthority === "RAW"
          ? t(`raw-grades.${kebabCase(gradeValue)}`)
          : gradeValue,
    },
    {
      args: { gradingAuthority },
      translationKey: "product.label",
      value: t(`category-rollup.${kebabCase(categoryRollup)}`),
    },
    gradingAuthority === "RAW"
      ? {}
      : {
          args: { gradingAuthority },
          translationKey: "product.barcode",
          value: barcode,
        },
    pageQuality
      ? {
          args: { gradingAuthority },
          translationKey: "product.page-quality",
          value: pageQuality,
        }
      : {},
    keyComments
      ? {
          args: { gradingAuthority },
          translationKey: "product.key-comments",
          value: keyComments,
          valueAsHTML: true,
        }
      : {},
    artComments
      ? {
          args: { gradingAuthority },
          translationKey: "product.art-comments",
          value: artComments,
          valueAsHTML: true,
        }
      : {},
    (slabDefects?.length ?? 0) > 0
      ? {
          translationKey: "product.slab-defects",
          value: slabDefects?.map((defect) =>
            t(`product.defects.${kebabCase(defect)}`),
          ),
        }
      : {},
    specialCopy
      ? {
          translationKey: "product.special-copy",
          value: t(`specialCopy.${kebabCase(specialCopy)}`),
        }
      : {},
    pedigree
      ? {
          translationKey: "product.pedigree",
          value: pedigree,
        }
      : {},
    signatures
      ? {
          translationKey: "product.signatures",
          value: signatures,
          valueAsHTML: true,
        }
      : {},
    publicGraderNotes
      ? {
          translationKey: "product.public-grader-notes",
          value: publicGraderNotes,
          valueAsHTML: true,
        }
      : {},
    gradeText
      ? {
          args: { gradingAuthority },
          translationKey: "product.grade-text",
          value: gradeText,
          valueAsHTML: true,
        }
      : {},
    submissionNotes
      ? {
          translationKey: "product.submission-notes",
          value: submissionNotes,
        }
      : {},
  ].filter(({ translationKey }) => translationKey != null);

  return (
    <div {...stylex.props(styles.list)}>
      {items.map(
        ({ args, translationKey, value, valueAsHTML }, ii) =>
          translationKey && (
            <div
              key={translationKey}
              {...stylex.props(
                auto,
                styles.item,
                ii % 2 === 0 && styles.itemOdd,
              )}
            >
              <SBProductDetailMeta
                args={args}
                translationKey={translationKey}
                value={value}
                valueAsHTML={valueAsHTML}
              />
            </div>
          ),
      )}
    </div>
  );
};

const styles = stylex.create({
  item: {
    boxSizing: "border-box",
    paddingLeft: {
      [MOBILE]: 0,
      [TABLET]: 0,
      default: 40,
    },
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: "50%",
    },
  },
  itemOdd: {
    paddingLeft: 0,
    paddingRight: {
      [MOBILE]: 0,
      [TABLET]: 0,
      default: 40,
    },
  },
  list: {
    alignItems: "start",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: 14,
    justifyContent: "flex-start",
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
});

export default ProductDetailMeta;
