/**
 * @generated SignedSource<<70beffc87f6de0a64b5691c53e2815bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuctionIdInput = {
  auctionId: string;
};
export type AuctionSearchHeadingExpirySubscription$variables = {
  input: AuctionIdInput;
};
export type AuctionSearchHeadingExpirySubscription$data = {
  readonly auctionExpirySubscription: {
    readonly " $fragmentSpreads": FragmentRefs<"AuctionSearchHeading_auction">;
  };
};
export type AuctionSearchHeadingExpirySubscription = {
  response: AuctionSearchHeadingExpirySubscription$data;
  variables: AuctionSearchHeadingExpirySubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "auctionExpirySubscriptionInput",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuctionSearchHeadingExpirySubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Auction",
        "kind": "LinkedField",
        "name": "auctionExpirySubscription",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuctionSearchHeading_auction"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuctionSearchHeadingExpirySubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Auction",
        "kind": "LinkedField",
        "name": "auctionExpirySubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "biddingStartsAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastLotClosesAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1b64a0c06eaa3050d037c58f52049cb",
    "id": null,
    "metadata": {},
    "name": "AuctionSearchHeadingExpirySubscription",
    "operationKind": "subscription",
    "text": "subscription AuctionSearchHeadingExpirySubscription(\n  $input: AuctionIdInput!\n) {\n  auctionExpirySubscription(auctionExpirySubscriptionInput: $input) {\n    ...AuctionSearchHeading_auction\n    id\n  }\n}\n\nfragment AuctionSearchHeading_auction on Auction {\n  id\n  biddingStartsAt\n  lastLotClosesAt\n  type\n  title\n}\n"
  }
};
})();

(node as any).hash = "9e65134a1d257a17618f5320d3e8e37f";

export default node;
