/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { config as GraphConfig } from "src/api/constants";
import { StorageKey } from "src/app/constants";
import {
  CONTACT_US_PATH,
  PRIVACY_PATH,
  TERMS_PATH,
} from "src/app/router/Router";
import {
  ButtonType,
  ButtonVariation,
  SBButton,
  SBIcon,
  SBLink,
  SBParagraph,
  SBTextInput,
  TextAutoCompleteType,
  TextInputType,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
  prefixPhoneNumber,
  refreshAnonymousToken,
} from "src/utils";
import { PHONE_RE } from "src/utils/constants";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const HTTP_UNAUTHORIZED = 401;
const HTTP_FORBIDDEN = 403;

const PHONE_URL = `${GraphConfig.API_PROTOCOL}://${GraphConfig.API_HOST}/verify-phone/send`;

type Props = Readonly<{
  onSubmit: (phoneNumber: string, country: string) => void;
}>;

const SignupPhone = ({ onSubmit }: Props): React.ReactNode | null => {
  const { t } = useTranslation();

  const controller = useRef(new AbortController());

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [country, setCountry] = useState("US");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [touchedFields, setTouchedFields] = useState({
    phoneNumber: false,
  });

  const submitTelephone = async (retry: boolean) => {
    setIsLoading(true);
    controller.current = new AbortController();
    try {
      const anonymousKey = StorageKey.Anonymous;
      const anonymousData = localStorage.getItem(anonymousKey);
      const anonymous =
        anonymousData == null ? null : JSON.parse(anonymousData);
      const anonymousToken: string = anonymous?.anonymousToken;

      const response = await fetch(PHONE_URL, {
        body: JSON.stringify({
          phoneNumber: prefixPhoneNumber(phoneNumber, country),
        }),
        headers: {
          Authorization: `Bearer ${anonymousToken ?? ""}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        method: "POST",
      });
      const json = await response.json();
      const statusCode = json?.error?.statusCode;
      const isTokenInvalid =
        statusCode === HTTP_UNAUTHORIZED || statusCode === HTTP_FORBIDDEN;
      if (isTokenInvalid && retry) {
        await refreshAnonymousToken();
        submitTelephone(false);
      } else if (json?.error != null) {
        setIsLoading(false);
        const message = json.error ?? t("verify.errors.default");
        setErrorMessage(message);
        return;
      }
      onSubmit(phoneNumber, country);
    } catch (error: unknown) {
      setIsLoading(false);
      let message = t("verify.errors.default");
      if (error instanceof Error && error?.message) {
        message = error?.message;
      }
      setErrorMessage(message);
    }
  };

  const handleChangePhoneNumber = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = formatPhoneNumber(
      event.currentTarget.value.replace(PHONE_RE, ""),
      country,
    );
    setPhoneNumber(value);
  };

  const handleBlurField = (
    event: React.FocusEvent<HTMLInputElement>,
    field: string,
  ) => {
    setTouchedFields((prevFields) => ({ ...prevFields, [field]: true }));
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setTouchedFields({
      phoneNumber: !isValidPhoneNumber(phoneNumber, country),
    });
    if (isValidPhoneNumber(phoneNumber, country)) {
      await submitTelephone(true);
    }
  };

  return (
    <div {...stylex.props(styles.form)}>
      <form method="post">
        <div aria-live="polite">
          {errorMessage ? (
            <div {...stylex.props(auto, styles.error)}>
              <SBIcon icon="error" style={styles.errorIcon} />
              <SBParagraph style={styles.errorMessage}>
                {errorMessage}
              </SBParagraph>
            </div>
          ) : null}
        </div>
        <SBParagraph style={styles.code}>{t("verify.code")}</SBParagraph>
        <fieldset {...stylex.props(styles.fieldset)}>
          <SBTextInput
            autoComplete={TextAutoCompleteType.TelNational}
            disabled={isLoading}
            errorMessage={
              touchedFields.phoneNumber
                ? !isValidPhoneNumber(phoneNumber, country) &&
                  t("verify.errors.field.phone-number")
                : null
            }
            id="phoneNumber"
            label={t("verify.field.phone-number")}
            placeholder={t("verify.field.phone-number")}
            style={styles.input}
            type={TextInputType.Text}
            value={phoneNumber}
            onBlur={(event) => {
              handleBlurField(event, "phoneNumber");
            }}
            onChange={handleChangePhoneNumber}
          />
        </fieldset>
        <SBParagraph style={styles.disclaimer}>
          {t("verify.privacy-and-terms.start")}
          <SBLink to={PRIVACY_PATH}>
            {t("verify.privacy-and-terms.privacy-link")}
          </SBLink>
          {t("verify.privacy-and-terms.middle")}
          <SBLink to={TERMS_PATH}>
            {t("verify.privacy-and-terms.terms-link")}
          </SBLink>
          {t("verify.privacy-and-terms.end")}
        </SBParagraph>
        <SBButton
          block={true}
          loading={isLoading}
          style={styles.verifyButton}
          title={t("verify.buttons.phone-number")}
          type={ButtonType.Submit}
          variation={ButtonVariation.Emphasis}
          onClick={handleSubmit}
        />
        <SBParagraph style={styles.help}>
          {t("verify.help.start")}
          <SBLink to={CONTACT_US_PATH}>
            {t("verify.help.contact-us-link")}
          </SBLink>
          {t("verify.help.end")}
        </SBParagraph>
      </form>
    </div>
  );
};

const styles = stylex.create({
  code: {
    marginBottom: 16,
    marginTop: 0,
    textAlign: "center",
  },
  disclaimer: {
    marginBottom: 16,
    marginTop: 0,
    textAlign: "center",
  },
  error: {
    alignItems: "flex-start",
    backgroundColor: colors.errorBackgroundColor,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 16,
    paddingBlock: 12,
    paddingInline: 16,
  },
  errorIcon: {
    color: colors.errorColor,
    marginRight: 8,
  },
  errorMessage: {
    color: colors.errorColor,
    fontSize: 16,
    lineHeight: 1.5,
    margin: 0,
  },
  fieldset: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  form: {
    padding: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 48,
    },
    position: "relative",
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: 448,
    },
  },
  help: {
    marginBlock: 0,
    textAlign: "center",
  },
  input: {
    marginBottom: 16,
  },
  verifyButton: {
    marginBottom: 16,
  },
});

export default SignupPhone;
