/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";
import type { Disposable } from "react-relay";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { SBActivityIndicator, SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

export type Content = readonly {
  caption?: string;
  description?: string | null;
  footnote?: string | null;
  id: string;
  label: string;
  value: string;
}[];

type Props = Readonly<{
  boldFirstRow?: boolean;
  chart?: React.ReactNode;
  content: Content;
  footer?: (string | string[]) | null;
  hasNext?: boolean;
  isLoadingNext?: boolean;
  loadNext?: (count: number) => Disposable;
  showEmpty?: boolean;
  style?: StyleXStyles;
  title: string;
}>;

export const SBCollapsibleTable = ({
  boldFirstRow = false,
  chart,
  content,
  footer,
  hasNext = false,
  isLoadingNext = false,
  loadNext,
  showEmpty,
  style,
  title,
}: Props): React.ReactNode | null => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(true);

  const onClickHeader = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  const onClickMore = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (hasNext && !isLoadingNext) {
      loadNext?.(5);
    }
  };

  const count = content.length;

  if (count === 0 && !showEmpty) {
    return null;
  }

  return (
    <div {...stylex.props(style)}>
      <div {...stylex.props(auto, styles.container)}>
        <a
          {...stylex.props(styles.toggle, !expanded && styles.toggleCollapsed)}
          aria-expanded={expanded}
          aria-label={t(`common.menus.${expanded ? "collapse" : "expand"}`, {
            content: title,
          })}
          href="#"
          onClick={onClickHeader}
        >
          <p {...stylex.props(styles.title)}>{title}</p>
          {count > 0 && (
            <SBIcon
              icon={expanded ? "expand_less" : "expand_more"}
              style={styles.icon}
            />
          )}
        </a>
        {expanded ? (
          <>
            {chart}
            <table {...stylex.props(styles.table)}>
              <tbody {...stylex.props(styles.data)}>
                {content.map(
                  (
                    { caption, description, footnote, id, label, value },
                    ii,
                  ) => {
                    return (
                      <tr
                        {...stylex.props(
                          auto,
                          styles.row,
                          ii % 2 === 0 ? styles.rowOdd : styles.rowEven,
                          ii + 1 === content.length &&
                            !hasNext &&
                            styles.rowLast,
                        )}
                        key={id}
                      >
                        <th
                          {...stylex.props(
                            styles.labelCell,
                            ii === 0 && boldFirstRow ? styles.cellBold : null,
                          )}
                          scope="row"
                        >
                          <div>
                            <p {...stylex.props(styles.label)}>
                              {label}
                              {footnote ? (
                                <sup {...stylex.props(styles.footnote)}>
                                  {footnote}
                                </sup>
                              ) : null}
                            </p>
                            {caption ? (
                              <p {...stylex.props(styles.caption)}>{caption}</p>
                            ) : null}
                          </div>
                        </th>
                        {description ? (
                          <td
                            {...stylex.props(
                              styles.descriptionCell,
                              ii === 0 && boldFirstRow ? styles.cellBold : null,
                            )}
                          >
                            <p {...stylex.props(styles.description)}>
                              {description}
                            </p>
                          </td>
                        ) : null}
                        <td
                          {...stylex.props(
                            styles.valueCell,
                            ii === 0 && boldFirstRow ? styles.cellBold : null,
                          )}
                        >
                          <p {...stylex.props(styles.value)}>{value}</p>
                        </td>
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
            {hasNext ? (
              <a
                {...stylex.props(auto, styles.showMoreLabel)}
                href="#"
                onClick={onClickMore}
              >
                <p {...stylex.props(styles.showMore)}>
                  {t("common.buttons.show-more")}
                </p>
                {isLoadingNext ? <SBActivityIndicator small={true} /> : null}
              </a>
            ) : null}
          </>
        ) : null}
      </div>
      {Boolean(footer) && (
        <div {...stylex.props(styles.footer)}>
          {Array.isArray(footer) ? (
            footer.map((text) => (
              <p {...stylex.props(auto, styles.footerText)} key={text}>
                {text}
              </p>
            ))
          ) : (
            <p {...stylex.props(auto, styles.footerText)}>{footer}</p>
          )}
        </div>
      )}
    </div>
  );
};

const styles = stylex.create({
  caption: {
    display: "flex",
    fontFamily: "Supreme-Regular",
    fontSize: 12,
    lineHeight: "16px",
    margin: 0,
  },
  cellBold: {
    fontWeight: 600,
  },
  container: {
    backgroundColor: colors.tableBackgroundColor,
    borderRadius: 8,
    color: colors.tableColor,
    fontSize: 14,
  },
  data: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  description: {
    fontFamily: "Supreme-Regular",
    fontSize: 14,
    fontVariantNumeric: "tabular-nums",
    lineHeight: "24px",
    margin: 0,
    textAlign: "left",
  },
  descriptionCell: {
    flexBasis: "33.333%",
  },
  footer: {
    marginTop: 4,
  },
  footerText: {
    color: colors.tableFooterColor,
    fontSize: 12,
    lineHeight: "16px",
    margin: 0,
  },
  footnote: {
    display: "flex",
    fontFamily: "Supreme-Regular",
    fontSize: 10,
    lineHeight: "16px",
    margin: 0,
    marginLeft: 2,
    top: 0,
  },
  icon: {
    color: colors.tableColor,
  },
  label: {
    display: "flex",
    fontFamily: "Supreme-Regular",
    fontSize: 14,
    lineHeight: "24px",
    margin: 0,
  },
  labelCell: {
    display: "flex",
    flexBasis: "66.666%",
    flexDirection: "column",
    flexGrow: 1,
    fontWeight: 400,
  },
  row: {
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: colors.tableBorderColor,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderTopWidth: 0,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
    paddingBlock: 12,
    paddingInline: 16,
  },
  rowEven: {
    backgroundColor: colors.tableEvenBackgroundColor,
  },
  rowLast: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  rowOdd: {
    backgroundColor: colors.tableOddBackgroundColor,
  },
  showMore: {
    fontFamily: "Supreme-Regular",
    fontSize: 14,
    lineHeight: "24px",
    margin: 0,
  },
  showMoreLabel: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomWidth: 1,
    borderColor: colors.tableBorderColor,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderTopWidth: 0,
    color: {
      ":hover": colors.tableColor,
      default: colors.tableColor,
    },
    display: "flex",
    justifyContent: "space-between",
    paddingBlock: 12,
    paddingInline: 16,
    textDecorationLine: "none",
  },
  table: {
    borderColor: colors.tableBorderColor,
    borderStyle: "solid",
    borderWidth: 0,
    display: "flex",
  },
  title: {
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 24,
    lineHeight: 0.875,
    margin: 0,
    textTransform: "uppercase",
  },
  toggle: {
    alignItems: "center",
    borderColor: colors.tableBorderColor,
    borderStyle: "solid",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 1,
    color: {
      ":hover": colors.tableColor,
      default: colors.tableColor,
    },
    display: "flex",
    justifyContent: "space-between",
    paddingBlock: 12,
    paddingInline: 16,
    textDecorationLine: "none",
  },
  toggleCollapsed: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  value: {
    fontFamily: "Supreme-Regular",
    fontSize: 14,
    fontVariantNumeric: "tabular-nums",
    lineHeight: "24px",
    margin: 0,
    textAlign: "right",
  },
  valueCell: {
    flexBasis: "33.333%",
  },
});
