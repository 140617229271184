/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  Currency,
  GradingAuthority,
} from "src/types/__generated__/AuctionSearchItem_auctionLot.graphql";

import * as stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AUCTION_LOT_PATH } from "src/app/router/Router";
import { HeadingLevel, SBHeading } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney, formatTitle } from "src/utils";

type Props = Readonly<{
  auctionLotId: string;
  currentBidAmount?: number | null | undefined;
  currentBidCurrency: Currency | null | undefined;
  grade: number | null | undefined;
  gradingAuthority: GradingAuthority | null | undefined;
  imageUrl: string;
  number: string;
  title: string;
}>;

export const SBAuctionLotTile = React.memo(
  ({
    auctionLotId,
    currentBidCurrency,
    currentBidAmount,
    grade,
    gradingAuthority,
    imageUrl,
    number,
    title,
  }: Props) => {
    const { i18n, t } = useTranslation();

    const [opacity, setOpacity] = useState(0);

    const destination = AUCTION_LOT_PATH.replace(":id", auctionLotId);

    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setOpacity(1);
      };
      img.src = imageUrl;
    }, [imageUrl]);

    const productTitle = formatTitle(t, title, number);

    const gradeValue =
      grade == null || grade === 0 ? t("raw-grades.0-0") : grade.toFixed(1);

    return (
      <li {...stylex.props(auto, styles.tile)}>
        <Link
          {...stylex.props(auto, styles.link)}
          rel="noopener noreferrer"
          target="_blank"
          to={destination}
        >
          <div
            {...stylex.props(auto, styles.image(opacity))}
            aria-hidden="true"
          >
            <img
              {...stylex.props(styles.imageFile)}
              height="100%"
              src={imageUrl}
              width="auto"
            />
            <div {...stylex.props(auto, styles.imageSpacer)}></div>
          </div>
          <div {...stylex.props(styles.meta)}>
            <SBHeading
              aria-label={t("product.title-alt", {
                authority: t(
                  `gradingAuthority.${kebabCase(gradingAuthority ?? "unknown")}`,
                ),
                number,
                title,
                value:
                  gradingAuthority === "RAW"
                    ? t(`raw-grades.${kebabCase(gradeValue)}`)
                    : gradeValue,
              })}
              level={HeadingLevel.H3}
            >
              {productTitle}
            </SBHeading>
            <p {...stylex.props(styles.grade)} aria-hidden="true">
              {t("product.grade-number", {
                gradingAuthority: t(
                  `gradingAuthority.${kebabCase(gradingAuthority ?? "unknown")}`,
                ),
                value:
                  gradingAuthority === "RAW"
                    ? t(`raw-grades.${kebabCase(gradeValue)}`)
                    : gradeValue,
              })}
            </p>
            <p {...stylex.props(styles.cost)}>
              <span {...stylex.props(styles.bid)}>
                {currentBidAmount == null ||
                currentBidAmount === 0 ||
                currentBidCurrency == null
                  ? t("auction.lot.no-bids")
                  : t("auction.lot.current-bid", {
                      currentBid: formatMoney(
                        currentBidAmount,
                        currentBidCurrency,
                        i18n.language,
                      ),
                    })}
              </span>
            </p>
          </div>
        </Link>
      </li>
    );
  },
);

const styles = stylex.create({
  bid: {
    fontSize: 20,
  },
  cost: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: 0,
    marginTop: 10,
  },
  grade: {
    color: colors.colorMuted,
    fontSize: 14,
    margin: 0,
    marginTop: 5,
  },
  image: (opacity: number) => ({
    alignItems: "center",
    backgroundColor: colors.backgroundPlaceholderColor,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: "flex",
    justifyContent: "center",
    opacity,
    overflow: "hidden",
    position: "relative",
    transition: "opacity 250ms",
  }),
  imageFile: {
    display: "block",
    height: "100%",
    position: "absolute",
  },
  imageSpacer: {
    paddingBottom: "100%",
  },
  link: {
    borderRadius: 8,
    display: "block",
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  meta: {
    color: colors.color,
    padding: 16,
  },
  tile: {
    backgroundColor: colors.backgroundEmphasisColor,
    backgroundSize: "contain",
    borderRadius: 8,
    listStyleType: "none",
    margin: 0,
    padding: 0,
    position: "relative",
  },
});
