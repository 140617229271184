/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountOfferCard_offer$key } from "src/types/__generated__/AccountOfferCard_offer.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { DateFormatType, SBDate, SBImage } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AccountOfferCard_offer$key;
}>;

const AccountOfferCard = ({ queryKey }: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const offer = useFragment(
    graphql`
      fragment AccountOfferCard_offer on Offer {
        id
        createdAt
        price
        currency
        status
        product {
          images(first: 1) {
            edges {
              node {
                url(quality: 100, width: 300, webp: true)
              }
            }
          }
          comicDetails {
            title
            number
            gradingAuthority
            grade
          }
        }
      }
    `,
    queryKey,
  );

  const { id, product, createdAt, status, price, currency } = offer;
  const { images, comicDetails } = product ?? {};
  const { title, number, gradingAuthority, grade } = comicDetails ?? {};

  return (
    <div key={id} {...stylex.props(styles.offer)}>
      <div>
        <SBImage alt="" height={200} src={images?.edges[0]?.node?.url ?? ""} />
      </div>
      <div>
        <div>{t("account.offers.comic-details1", { number, title })}</div>
        <div>
          {t("account.offers.comic-details2", { grade, gradingAuthority })}
        </div>
        <div>{formatMoney(price, currency, i18n.language)}</div>
        <div>{status}</div>
        <div>
          <SBDate dateString={createdAt} format={DateFormatType.Long} />
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  offer: {
    backgroundColor: colors.backgroundEmphasisColor,
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(3, 1fr)",
      default: "repeat(4, 1fr)",
    },
    marginBottom: 10,
    padding: 12,
  },
});

export default AccountOfferCard;
