/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { Appearance, SetupIntent } from "@stripe/stripe-js";
import type { AuctionLotView_auctionLot$key } from "src/types/__generated__/AuctionLotView_auctionLot.graphql";
import type { AuctionLotView_user$key } from "src/types/__generated__/AuctionLotView_user.graphql";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useSubscription } from "react-relay";

import { STRIPE_KEY } from "src/api/constants";
import CensusTable from "src/app/components/census/CensusTable";
import HistoricalSalesTable from "src/app/components/historical-sales/HistoricalSalesTable";
import { PageContext } from "src/app/context/page";
import { getProductGrade, getProductTitle } from "src/app/utils/product.util";
import { SBImageCarousel } from "src/sbxui";

import AuctionLotDescription from "./AuctionLotDescription";
import AuctionLotHeader from "./AuctionLotHeader";
import AuctionLotMeta from "./AuctionLotMeta";
import AuctionLotVerifyCreditCard from "./AuctionLotVerifyCreditCard";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_KEY);

const POLL_TIME_MS = 500;

const AuctionLotViewBidSubscription = graphql`
  subscription AuctionLotViewBidSubscription(
    $input: BidAcceptedSubscriptionInput!
  ) {
    bidAcceptedSubscription(bidAcceptedSubscriptionInput: $input) {
      ...AuctionLotView_auctionLot
    }
  }
`;

const AuctionLotViewExpirySubscription = graphql`
  subscription AuctionLotViewExpirySubscription($input: AuctionLotIdInput!) {
    auctionLotExpirySubscription(auctionLotExpirySubscription: $input) {
      ...AuctionLotView_auctionLot
    }
  }
`;

type Props = Readonly<{
  queryKey: AuctionLotView_auctionLot$key;
  queryKeyUser: AuctionLotView_user$key | null | undefined;
}>;

const AuctionLotView = ({ queryKey, queryKeyUser }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const userData = useFragment(
    graphql`
      fragment AuctionLotView_user on User {
        paymentSheetSetupIntent {
          setupIntent
        }
        paymentMethods {
          id
        }
      }
    `,
    queryKeyUser,
  );

  const data = useFragment(
    graphql`
      fragment AuctionLotView_auctionLot on AuctionLot {
        id
        currentBidAmount
        currentBidCurrency
        ...AuctionLotDescription_auctionLot
        product {
          ...AuctionLotHeader_product
          ...AuctionLotMeta_product
          ...HistoricalSalesTable_product
          images {
            edges {
              node {
                url(quality: 100, webp: true, width: 1000)
              }
            }
          }
          comicDetails {
            ...CensusTable_comicDetail
            title
            publisher
            year
            number
            gradingAuthority
            grade
            keyComments
            artComments
          }
        }
      }
    `,
    queryKey,
  );

  const auctionLotId = data.id;

  const comicDetails = data.product?.comicDetails;
  const images = data.product?.images;
  const { title, grade, gradingAuthority, number } = comicDetails ?? {};

  const imageEdges = images?.edges ?? [];
  const imageUrls = imageEdges.map(({ node }) => node.url);

  const paymentMethods = userData?.paymentMethods ?? [];
  const hasPaymentMethod = paymentMethods.length > 0;

  const productTitle = `${getProductTitle({
    number: number ?? "",
    title: title ?? "",
  })} ${getProductGrade({ grade: grade ?? 0, gradingAuthority: t(`gradingAuthority.${kebabCase(gradingAuthority) ?? "unknown"}`) })}`;

  const appearance = useMemo<Appearance>(() => {
    if (window.matchMedia?.("(prefers-color-scheme: dark)").matches) {
      return {
        rules: {
          ".Label": {
            color: "#ffffff",
          },
        },
        variables: {
          borderRadius: "24px",
          colorBackground: "#ffffff",
          colorDanger: "#fd1a1f",
          colorPrimary: "#1b1b1d",
          colorSuccess: "#4cd964",
          colorText: "#1b1b1d",
          colorTextPlaceholder: "#676767",
          colorWarning: "#f37500",
          spacingUnit: "4px",
        },
      };
    }
    return {
      variables: {
        borderRadius: "24px",
        colorBackground: "#ffffff",
        colorDanger: "#fd1a1f",
        colorPrimary: "#1b1b1d",
        colorSuccess: "#4cd964",
        colorText: "#1b1b1d",
        colorTextPlaceholder: "#808080",
        colorWarning: "#f37500",
        spacingUnit: "4px",
      },
    };
  }, []);

  const [isCreditCardVerified, setIsCreditCardVerified] =
    useState(hasPaymentMethod);

  const pageContext = useContext(PageContext);

  const bidConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionLotViewBidSubscription,
      variables: {
        input: {
          auctionLotId,
        },
      },
    }),
    [auctionLotId],
  );

  const expiryConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionLotViewExpirySubscription,
      variables: {
        input: {
          auctionLotId,
        },
      },
    }),
    [auctionLotId],
  );

  useSubscription(bidConfig);
  useSubscription(expiryConfig);

  const handleVerifyCreditCardComplete = (result: SetupIntent.Status) => {
    if (result === "succeeded") {
      setIsCreditCardVerified(true);
    } else {
      setIsCreditCardVerified(false);
    }
  };

  const options = {
    appearance,
    clientSecret: userData?.paymentSheetSetupIntent.setupIntent,
  };

  useEffect(() => {
    pageContext?.setTitle(
      t("auctions.lot.document-title", {
        product: productTitle,
      }),
    );
  }, [productTitle, pageContext, t]);

  // TODO Remove dev-only check here.
  const shouldShowPaymentElement =
    __DEV__ && userData != null && !isCreditCardVerified;

  return (
    <div {...stylex.props(styles.product)}>
      <div {...stylex.props(styles.columns)}>
        <div {...stylex.props(styles.columnLeftMobile)}>
          {data.product ? <AuctionLotHeader queryKey={data.product} /> : null}
          <div {...stylex.props(styles.carousel)}>
            <SBImageCarousel imageUrls={imageUrls} />
          </div>
          {data.product ? <AuctionLotDescription queryKey={data} /> : null}
          {data.product ? <AuctionLotMeta queryKey={data.product} /> : null}
        </div>
        <div {...stylex.props(styles.columnRightMobile)}>
          {data.product ? (
            <HistoricalSalesTable queryKey={data.product} />
          ) : null}
          {comicDetails ? <CensusTable queryKey={comicDetails} /> : null}
        </div>
        <div {...stylex.props(styles.columnLeft)}>
          <div {...stylex.props(styles.carousel)}>
            <SBImageCarousel imageUrls={imageUrls} />
          </div>
          {data.product ? <AuctionLotMeta queryKey={data.product} /> : null}
        </div>
        <div {...stylex.props(styles.columnRight)}>
          {data.product ? <AuctionLotHeader queryKey={data.product} /> : null}
          {data.product ? <AuctionLotDescription queryKey={data} /> : null}
          {data.product ? (
            <HistoricalSalesTable queryKey={data.product} />
          ) : null}
          {comicDetails ? <CensusTable queryKey={comicDetails} /> : null}
        </div>
      </div>
      {shouldShowPaymentElement ? (
        <Elements options={options} stripe={stripePromise}>
          <AuctionLotVerifyCreditCard
            auctionLotId={auctionLotId}
            onComplete={handleVerifyCreditCardComplete}
          />
        </Elements>
      ) : null}
    </div>
  );
};

const styles = stylex.create({
  backButton: {
    marginBottom: 40,
  },
  carousel: {
    marginBottom: 24,
    position: "relative",
  },
  census: {
    marginTop: 40,
  },
  censusTable: {
    marginTop: 10,
  },
  columnLeft: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "grid",
      default: "block",
    },
    gridArea: {
      default: "1 / 1 / 2 / 6",
    },
    marginBottom: 24,
  },
  columnLeftMobile: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "none",
      default: "none",
    },
  },
  columnRight: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "block",
      default: "block",
    },
    gridArea: "1 / 7 / 2 / 11",
    marginBottom: 24,
  },
  columnRightMobile: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "none",
      default: "none",
    },
  },
  columns: {
    display: "grid",
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(10, 1fr)",
      default: "repeat(10, 1fr)",
    },
    margin: 0,
  },
  favorite: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  product: {
    marginBottom: 96,
  },
});

export default AuctionLotView;
