/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SpecialCopy } from "src/types/__generated__/ProductDetailMeta_product.graphql";
import type {
  CategoryRollup,
  GradingAuthority,
  Vertical,
} from "src/types/__generated__/ShopPaginationQuery.graphql";

import * as React from "react";
import { useCallback, useMemo, useState } from "react";

export enum Sort {
  DateAsc = "createdAt-asc",
  DateDesc = "createdAt-desc",
  FmvDividendAsc = "fmvDividend-asc",
  FmvDividendDesc = "fmvDividend-desc",
  GradeAsc = "grade-asc",
  GradeDesc = "grade-desc",
  PriceAsc = "price-asc",
  PriceDesc = "price-desc",
  TitleAsc = "title-asc",
  TitleDesc = "title-desc",
}

export const DEFAULT_SORT = Sort.DateDesc;

interface State {
  categoryRollups: CategoryRollup[];
  gradeMax: number | null;
  gradeMin: number | null;
  gradingAuthorities: GradingAuthority[];
  isSearching: boolean;
  priceMax: number | null;
  priceMin: number | null;
  resetSearch: () => void;
  savedSearchId: string | null;
  searchTerm: string;
  sellerId: string | null;
  setCategoryRollups: (categoryRollups: CategoryRollup[]) => void;
  setGradeMax: (gradeMax: number | null) => void;
  setGradeMin: (gradeMin: number | null) => void;
  setGradingAuthorities: (gradingAuthorities: GradingAuthority[]) => void;
  setIsSearching: (isSearching: boolean) => void;
  setPriceMax: (priceMax: number | null) => void;
  setPriceMin: (priceMin: number | null) => void;
  setSavedSearchId: (savedSearchId: string | null) => void;
  setSearchTerm: (searchTerm: string) => void;
  setSellerId: (sellerId: string | null) => void;
  setShopName: (shopName: string | null) => void;
  setShopUrl: (shopUrl: string | null | undefined) => void;
  setSort: (sort: Sort) => void;
  setSpecialCopies: (specialCopies: SpecialCopy[]) => void;
  setVertical: (vertical: Vertical) => void;
  setYearMax: (yearMax: number | null) => void;
  setYearMin: (yearMin: number | null) => void;
  shopName: string | null;
  shopUrl: string | null | undefined;
  sort: Sort;
  specialCopies: SpecialCopy[];
  vertical: Vertical;
  yearMax: number | null;
  yearMin: number | null;
}

const INITIAL_STATE: State = {
  categoryRollups: [],
  gradeMax: null,
  gradeMin: null,
  gradingAuthorities: [],
  isSearching: false,
  priceMax: null,
  priceMin: null,
  resetSearch: () => {
    return;
  },
  savedSearchId: null,
  searchTerm: "",
  sellerId: null,
  setCategoryRollups: () => {
    return;
  },
  setGradeMax: () => {
    return;
  },
  setGradeMin: () => {
    return;
  },
  setGradingAuthorities: () => {
    return;
  },
  setIsSearching: () => {
    return;
  },
  setPriceMax: () => {
    return;
  },
  setPriceMin: () => {
    return;
  },
  setSavedSearchId: () => {
    return;
  },
  setSearchTerm: () => {
    return;
  },
  setSellerId: () => {
    return;
  },
  setShopName: () => {
    return;
  },
  setShopUrl: () => {
    return;
  },
  setSort: () => {
    return;
  },
  setSpecialCopies: () => {
    return;
  },
  setVertical: () => {
    return;
  },
  setYearMax: () => {
    return;
  },
  setYearMin: () => {
    return;
  },
  shopName: null,
  shopUrl: null,
  sort: DEFAULT_SORT,
  specialCopies: [],
  vertical: "COMIC",
  yearMax: null,
  yearMin: null,
};

export const VerticalSearchContext: React.Context<State> =
  React.createContext(INITIAL_STATE);

type Props = React.PropsWithChildren;

export const VerticalSearchProvider = (props: Props): React.ReactNode => {
  const { children } = props;

  // Whether search is open
  const [isSearching, setIsSearching] = useState<boolean>(
    INITIAL_STATE.isSearching,
  );

  // Saved searches
  const [savedSearchId, setSavedSearchId] = useState<string | null>(
    INITIAL_STATE.savedSearchId,
  );

  // Sort type and direction
  const [sort, setSort] = useState<Sort>(INITIAL_STATE.sort);

  // Filters
  const [gradeMax, setGradeMax] = useState<number | null>(
    INITIAL_STATE.gradeMax,
  );
  const [gradeMin, setGradeMin] = useState<number | null>(
    INITIAL_STATE.gradeMin,
  );
  const [priceMax, setPriceMax] = useState<number | null>(
    INITIAL_STATE.priceMax,
  );
  const [priceMin, setPriceMin] = useState<number | null>(
    INITIAL_STATE.priceMin,
  );
  const [yearMax, setYearMax] = useState<number | null>(INITIAL_STATE.yearMax);
  const [yearMin, setYearMin] = useState<number | null>(INITIAL_STATE.yearMin);

  const [gradingAuthorities, setGradingAuthorities] = useState<
    GradingAuthority[]
  >(INITIAL_STATE.gradingAuthorities);

  const [specialCopies, setSpecialCopies] = useState<SpecialCopy[]>(
    INITIAL_STATE.specialCopies,
  );

  const [categoryRollups, setCategoryRollups] = useState<CategoryRollup[]>(
    INITIAL_STATE.categoryRollups,
  );

  // Vertical
  const [vertical, setVertical] = useState<Vertical>(INITIAL_STATE.vertical);

  // Search term
  const [searchTerm, setSearchTerm] = useState<string>(
    INITIAL_STATE.searchTerm,
  );

  // Seller shops
  const [sellerId, setSellerId] = useState<string | null>(
    INITIAL_STATE.sellerId,
  );
  const [shopName, setShopName] = useState<string | null>(
    INITIAL_STATE.shopName,
  );
  const [shopUrl, setShopUrl] = useState<string | null | undefined>(
    INITIAL_STATE.shopUrl,
  );

  const resetSearch = useCallback(() => {
    setCategoryRollups(INITIAL_STATE.categoryRollups);
    setGradeMax(INITIAL_STATE.gradeMax);
    setGradeMin(INITIAL_STATE.gradeMin);
    setGradingAuthorities(INITIAL_STATE.gradingAuthorities);
    setIsSearching(INITIAL_STATE.isSearching);
    setPriceMax(INITIAL_STATE.priceMax);
    setPriceMin(INITIAL_STATE.priceMin);
    setSavedSearchId(INITIAL_STATE.savedSearchId);
    setSellerId(INITIAL_STATE.sellerId);
    setShopName(INITIAL_STATE.shopName);
    setShopUrl(INITIAL_STATE.shopUrl);
    setSort(INITIAL_STATE.sort);
    setSpecialCopies(INITIAL_STATE.specialCopies);
    setSearchTerm(INITIAL_STATE.searchTerm);
    setVertical(INITIAL_STATE.vertical);
    setYearMax(INITIAL_STATE.yearMax);
    setYearMin(INITIAL_STATE.yearMin);
  }, []);

  const currentShop = useMemo(
    () => ({
      categoryRollups,
      gradeMax,
      gradeMin,
      gradingAuthorities,
      isSearching,
      priceMax,
      priceMin,
      resetSearch,
      savedSearchId,
      searchTerm,
      sellerId,
      setCategoryRollups,
      setGradeMax,
      setGradeMin,
      setGradingAuthorities,
      setIsSearching,
      setPriceMax,
      setPriceMin,
      setSavedSearchId,
      setSearchTerm,
      setSellerId,
      setShopName,
      setShopUrl,
      setSort,
      setSpecialCopies,
      setVertical,
      setYearMax,
      setYearMin,
      shopName,
      shopUrl,
      sort,
      specialCopies,
      vertical,
      yearMax,
      yearMin,
    }),
    [
      categoryRollups,
      gradeMax,
      gradeMin,
      gradingAuthorities,
      isSearching,
      priceMax,
      priceMin,
      resetSearch,
      savedSearchId,
      sellerId,
      shopName,
      shopUrl,
      sort,
      specialCopies,
      searchTerm,
      vertical,
      yearMax,
      yearMin,
    ],
  );

  return (
    <VerticalSearchContext.Provider value={currentShop}>
      {children}
    </VerticalSearchContext.Provider>
  );
};
