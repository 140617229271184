/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

const WHITESPACE_RE = /[\s]+/gu;
const ELLIPSIS_RE = /\.\.\./gu;
const EN_DASH_RE = / - /gu;
const APOSTROPHE_RE = /'/gu;
const UNDERSCORE_RE = /_/gu;

export const formatShopName = (
  shopName?: string | null | undefined,
  shopUrl?: string | null | undefined,
): string | null => {
  if (shopName != null) {
    return shopName;
  }

  if (shopUrl != null) {
    return shopUrl
      .replace(WHITESPACE_RE, " ")
      .replace(UNDERSCORE_RE, " ")
      .replace(ELLIPSIS_RE, "\u2026")
      .replace(APOSTROPHE_RE, "\u2019")
      .replace(EN_DASH_RE, " \u2013 ");
  }

  return null;
};
