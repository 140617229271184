/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { Appearance, SetupIntent } from "@stripe/stripe-js";

import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { config as GraphConfig } from "src/api/constants";
import {
  ButtonType,
  ButtonVariation,
  SBButton,
  SBErrorMessage,
} from "src/sbxui";

type Props = Readonly<{
  auctionLotId: string;
  onComplete: (result: SetupIntent.Status) => void;
}>;

const AuctionLotVerifyCreditCard = ({
  auctionLotId,
  onComplete,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null,
  );

  const stripe = useStripe();
  const elements = useElements();

  const dark: Appearance = useMemo(
    () => ({
      rules: {
        ".Label": {
          color: "#ffffff",
        },
      },
      variables: {
        borderRadius: "24px",
        colorBackground: "#ffffff",
        colorDanger: "#fd1a1f",
        colorPrimary: "#1b1b1d",
        colorSuccess: "#4cd964",
        colorText: "#1b1b1d",
        colorTextPlaceholder: "#676767",
        colorWarning: "#f37500",
        spacingUnit: "4px",
      },
    }),
    [],
  );

  const light: Appearance = useMemo(
    () => ({
      variables: {
        borderRadius: "24px",
        colorBackground: "#ffffff",
        colorDanger: "#fd1a1f",
        colorPrimary: "#1b1b1d",
        colorSuccess: "#4cd964",
        colorText: "#1b1b1d",
        colorTextPlaceholder: "#808080",
        colorWarning: "#f37500",
        spacingUnit: "4px",
      },
    }),
    [],
  );

  useEffect(() => {
    const colorTheme = window.matchMedia?.("(prefers-color-scheme: dark)");
    colorTheme.onchange = (event) => {
      if (event.matches) {
        elements?.update({ appearance: dark });
      } else {
        elements?.update({ appearance: light });
      }
    };
  }, [dark, elements, light]);

  const handleSubmitStripeVerification = async (
    event: React.SyntheticEvent,
  ) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmSetup({
      confirmParams: {
        // eslint-disable-next-line camelcase
        return_url: `${GraphConfig.WEB_URL}/auction-lot/${encodeURIComponent(auctionLotId)}`,
      },
      elements,
      redirect: "if_required",
    });

    setIsLoading(false);

    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      onComplete(result.setupIntent.status);
    }
  };

  return (
    <>
      <SBErrorMessage message={errorMessage} style={styles.error} />
      <PaymentElement />
      <SBButton
        block={true}
        loading={isLoading}
        style={styles.button}
        title={t("auction.lot.verify-bidder-modal.buttons.submit-credit-card")}
        type={ButtonType.Submit}
        variation={ButtonVariation.Emphasis}
        onClick={handleSubmitStripeVerification}
      />
    </>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 24,
  },
  error: {
    marginBottom: 24,
  },
});

export default AuctionLotVerifyCreditCard;
