/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ARTICLES_PATH,
  AUCTIONS_PATH,
  FAQS_PATH,
  SHOP_PATH,
} from "src/app/router/Router";
import { MenuPosition, SBIcon, SBMenu } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

const TopNavigationMobile = (): React.ReactNode => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();

  const handleClickTab = (path: string) => (event: React.SyntheticEvent) => {
    event.preventDefault();
    startTransition(() => {
      navigate(path);
    });
  };

  const items = [
    {
      disabled: isPending,
      id: "auctions",
      isVisible: __DEV__,
      label: t("auctions.nav-title"),
      onClick: handleClickTab(AUCTIONS_PATH),
      to: AUCTIONS_PATH,
    },
    {
      disabled: isPending,
      id: "shop",
      isVisible: true,
      label: t("shop.nav-title"),
      onClick: handleClickTab(SHOP_PATH),
      to: SHOP_PATH,
    },
    {
      disabled: isPending,
      id: "articles",
      isVisible: true,
      label: t("blog.nav-title"),
      onClick: handleClickTab(ARTICLES_PATH),
      to: ARTICLES_PATH,
    },
    {
      disabled: isPending,
      id: "faqs",
      isVisible: true,
      label: t("faqs.nav-title"),
      onClick: handleClickTab(FAQS_PATH),
      to: FAQS_PATH,
    },
  ];

  return (
    <SBMenu
      button={<SBIcon icon="menu" style={styles.icon} />}
      buttonLabel={t("menu.label")}
      buttonStyle={styles.buttonStyle}
      items={items.filter(({ isVisible }) => isVisible)}
      position={MenuPosition.RIGHT}
    />
  );
};

const styles = stylex.create({
  buttonStyle: {
    alignItems: "center",
    backgroundColor: colors.topNavigationBackgroundColor,
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    height: 48,
    justifyContent: "center",
    padding: 4,
  },
  icon: {
    color: colors.topNavigationMenuColor,
  },
});

export default React.memo(TopNavigationMobile);
