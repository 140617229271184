/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { Sort } from "src/app/context/vertical-search";

import { DEFAULT_SORT } from "src/app/context/vertical-search";
import { SHOP_PATH } from "src/app/router/Router";

const appendValue = (query: string, value: string): string => {
  let newQuery = query;
  if (query.startsWith(`${SHOP_PATH}?`)) {
    newQuery += "&";
  }
  newQuery += `sort=${encodeURIComponent(value)}`;
  return newQuery;
};

type Options = Readonly<{
  searchTerm: string;
  sort: Sort;
}>;

export const getUrlFromShopSearch = ({ searchTerm, sort }: Options) => {
  let query = SHOP_PATH;

  if (searchTerm !== "") {
    query += `?term=${encodeURIComponent(searchTerm)}`;
  }

  if (sort !== DEFAULT_SORT) {
    query = appendValue(query, sort);
  }

  return query;
};
