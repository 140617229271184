/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  APP_STORE_IMG,
  APP_STORE_URL,
  CARBON,
  GOOGLE_PLAY_IMG,
  GOOGLE_PLAY_URL,
  SHORTBOXED,
  SHORTBOXED_CDN_BASE_URL,
} from "src/app/constants";
import {
  CONTACT_US_PATH,
  PRIVACY_PATH,
  TERMS_PATH,
  WHY_USE_SHORTBOXED_PATH,
} from "src/app/router/Router";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const ANDROID_RE = /android/iu;
const IPHONE_RE = /iphone/iu;

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const SHORTBOXED_LOGO = `${SHORTBOXED_CDN_BASE_URL}/logo-square.webp`;
const CARBON_LOGO = `${SHORTBOXED_CDN_BASE_URL}/carbon.webp`;
const FOOTER_IMG = `${SHORTBOXED_CDN_BASE_URL}/footer.webp`;

const CARBON_URL = "https://carbonagency.co/";

/* eslint-disable sort-keys-plus/sort-keys */
const SOCIAL_LINKS = {
  discord: "https://discord.com/invite/shortboxed",
  instagram: "https://www.instagram.com/shortboxed/",
  youTube: "https://www.youtube.com/channel/UCIZawxdAlJVYiZQC8G00_Iw",
  facebook: "https://www.facebook.com/shortboxed/",
  tikTok: "https://www.tiktok.com/@shortboxedcomics",
  x: "https://x.com/shortboxed",
};
/* eslint-enable sort-keys-plus/sort-keys */

const Footer = (): React.ReactNode => {
  const { t } = useTranslation();

  const companyLinks = [
    { whyUseShortboxed: WHY_USE_SHORTBOXED_PATH },
    { contactUs: CONTACT_US_PATH },
  ];

  const { userAgent } = navigator;

  const isAndroid = ANDROID_RE.test(userAgent);
  const isPhone = IPHONE_RE.test(userAgent);

  const isDesktop = !isAndroid && !isPhone;

  const showAndroid = isAndroid || isDesktop;
  const showIphone = isPhone || isDesktop;

  return (
    <footer>
      <div {...stylex.props(auto, styles.top)}>
        <div {...stylex.props(auto, styles.inner, styles.topContent)}>
          <img
            {...stylex.props(styles.logo)}
            alt={t("footer.shortboxed.logo-alt", {
              company: SHORTBOXED,
            })}
            height="64"
            src={SHORTBOXED_LOGO}
            width="64"
          />
          <nav
            {...stylex.props(styles.links)}
            aria-label={t("footer.links.nav-label")}
          >
            <div {...stylex.props(styles.linksGroup, styles.linksGroupFirst)}>
              <h2 {...stylex.props(auto, styles.linksHeader)}>
                {t("footer.links.company")}
              </h2>
              <ul {...stylex.props(styles.linksList)}>
                {companyLinks.map((item) => {
                  const [key, path] = Object.entries(item)[0];
                  return (
                    <li {...stylex.props(styles.linksListItem)} key={key}>
                      <Link {...stylex.props(auto, styles.linksLink)} to={path}>
                        {t(`footer.links.${kebabCase(key)}`)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div {...stylex.props(styles.linksGroup)}>
              <h2 {...stylex.props(auto, styles.linksHeader)}>
                {t("footer.links.social")}
              </h2>
              <ul {...stylex.props(styles.linksList)}>
                {Object.keys(SOCIAL_LINKS).map((key) => (
                  <li {...stylex.props(styles.linksListItem)} key={key}>
                    <a
                      {...stylex.props(auto, styles.linksLink)}
                      href={SOCIAL_LINKS[key as keyof typeof SOCIAL_LINKS]}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t(`footer.links.${kebabCase(key)}`)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
          <div {...stylex.props(styles.download)}>
            <img
              alt={t("footer.download.image-alt")}
              height={404}
              src={FOOTER_IMG}
              width={320}
            />
            <div>
              <h2 {...stylex.props(auto, styles.downloadHeading)}>
                {t("footer.download.heading")}
              </h2>
              <div {...stylex.props(styles.downloadButtons)}>
                {showIphone ? (
                  <a
                    {...stylex.props(auto, styles.downloadLink)}
                    aria-label={t("home.download-ios")}
                    href={APP_STORE_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      alt={t("home.app-store")}
                      height="64"
                      src={APP_STORE_IMG}
                      width="150"
                    />
                  </a>
                ) : null}
                {showAndroid ? (
                  <a
                    {...stylex.props(auto, styles.downloadLink)}
                    aria-label={t("home.download-android")}
                    href={GOOGLE_PLAY_URL}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      alt={t("home.google-play")}
                      height="64"
                      src={GOOGLE_PLAY_IMG}
                      width="165"
                    />
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div {...stylex.props(auto, styles.bottom)}>
        <div {...stylex.props(auto, styles.inner, styles.bottomContent)}>
          <p {...stylex.props(styles.legal)}>
            {t("footer.copyright", {
              company: SHORTBOXED,
              date: new Date(Date.now()).getFullYear(),
            })}
            {/* eslint-disable-next-line react/jsx-no-literals */}
            {"\u00a0| "}
            <Link {...stylex.props(styles.bottomLink)} to={PRIVACY_PATH}>
              {t("footer.privacy.start")}
            </Link>
            {t("footer.privacy.middle")}
            <Link {...stylex.props(styles.bottomLink)} to={TERMS_PATH}>
              {t("footer.privacy.end")}
            </Link>
          </p>
          <p {...stylex.props(styles.design)}>
            <a
              {...stylex.props(auto, styles.designLink)}
              aria-label={t("footer.design.attribution", {
                company: CARBON,
              })}
              href={CARBON_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                {...stylex.props(styles.designLogo)}
                alt={t("footer.design.logo-alt", {
                  company: CARBON,
                })}
                height="20"
                src={CARBON_LOGO}
                width="20"
              />
              <span>
                {t("footer.design.attribution", {
                  company: CARBON,
                })}
              </span>
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

const styles = stylex.create({
  bottom: {
    backgroundColor: colors.footerBackgroundColor,
    borderColor: colors.footerBorderColor,
    borderTopStyle: "solid",
    borderTopWidth: 1,
  },
  bottomContent: {
    alignItems: "center",
    color: colors.footerEmphasisColor,
    height: 64,
    justifyContent: "space-between",
  },
  bottomLink: {
    color: {
      ":hover": colors.footerBottomLinkColor,
      default: colors.footerBottomLinkColor,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  design: {
    fontSize: 14,
    textAlign: {
      [MOBILE]: "left",
      [TABLET]: "left",
      default: "right",
    },
    zIndex: 1,
  },
  designLink: {
    alignItems: "center",
    color: {
      ":hover": colors.footerBottomLinkColor,
      default: colors.footerBottomLinkColor,
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  designLogo: {
    marginRight: 8,
    overflow: "hidden",
  },
  download: {
    alignItems: "end",
    bottom: -108,
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "flex",
    },
    justifyContent: "flex-end",
    position: "absolute",
    right: 0,
    width: 544,
  },
  downloadButtons: {
    alignItems: "center",
    display: "flex",
    marginBottom: 96,
    marginLeft: 8,
  },
  downloadHeading: {
    color: colors.homeCarouselColor,
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 32,
    fontWeight: 400,
    lineHeight: 0.875,
    margin: 0,
    marginBottom: 4,
    marginLeft: 16,
    textTransform: "uppercase",
  },
  downloadLink: {
    borderRadius: 8,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  inner: {
    color: colors.footerColor,
    display: "flex",
    marginInline: "auto",
    width: {
      [MOBILE]: "95%",
      [TABLET]: "95%",
      default: 1200,
    },
  },
  legal: {
    fontSize: 14,
  },
  links: {
    alignItems: "start",
    display: "flex",
    flexDirection: {
      [MOBILE]: "column",
      [TABLET]: "column",
      default: "row",
    },
    justifyContent: "flex-start",
  },
  linksGroup: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    marginLeft: 24,
  },
  linksGroupFirst: {
    marginRight: {
      [MOBILE]: 0,
      [TABLET]: 0,
      default: 56,
    },
  },
  linksHeader: {
    color: colors.footerEmphasisColor,
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 24,
    fontWeight: 400,
    margin: 0,
    marginBottom: 16,
    marginLeft: 40,
    textTransform: "uppercase",
  },
  linksLink: {
    color: {
      ":hover": colors.footerBottomLinkColor,
      default: colors.footerColor,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  linksList: {
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    fontSize: 14,
    height: {
      [MOBILE]: "auto",
      [TABLET]: "auto",
      default: 176,
    },
    justifyContent: "flex-start",
    listStyleType: "none",
    margin: 0,
    marginBottom: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: -8,
    },
    padding: 0,
  },
  linksListItem: {
    marginBottom: 12,
    marginLeft: 40,
    whiteSpace: "nowrap",
  },
  logo: {
    marginLeft: {
      [MOBILE]: 20,
      [TABLET]: 20,
      default: 0,
    },
    marginTop: -8,
  },
  top: {
    backgroundColor: colors.footerBackgroundColor,
    paddingBlock: 44,
  },
  topContent: {
    alignItems: "start",
    justifyContent: "flex-start",
    overflow: "visible",
    paddingRight: {
      [MOBILE]: 0,
      [TABLET]: 0,
      default: 600,
    },
    position: "relative",
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: 600,
    },
  },
});

export default React.memo(Footer);
