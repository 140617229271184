/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { OrderDetailView_order$key } from "src/types/__generated__/OrderDetailView_order.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { PageContext } from "src/app/context/page";
import { getProductGrade, getProductTitle } from "src/app/utils/product.util";
import {
  DateFormatType,
  HeadingLevel,
  SBBackButton,
  SBButton,
  SBDate,
  SBHeading,
  SBImage,
  SBImageCarousel,
} from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

import { ReportProblemModal } from "./ReportProblemModal";

// const MOBILE = "@media (max-width: 767px)";
// const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  onClose: () => void;
  queryKey: OrderDetailView_order$key;
}>;

const OrderDetailView = ({ onClose, queryKey }: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const [isReportProblemModalOpen, setIsReportProblemModalOpen] =
    useState(false);

  const data = useFragment(
    graphql`
      fragment OrderDetailView_order on Order {
        id
        currency
        surrogateId
        shippingLabel {
          trackingId
        }
        country
        address1
        address2
        locality
        region
        postalCode
        givenName
        familyName
        createdAt
        fees
        subTotal
        total
        tax
        shipping
        paymentMethod
        product {
          id
          images {
            edges {
              node {
                url(quality: 100, width: 1000, webp: true)
              }
            }
          }
          comicDetails {
            title
            grade
            gradingAuthority
            number
          }
        }
      }
    `,
    queryKey,
  );

  const {
    id: orderId,
    product,
    surrogateId,
    shippingLabel,
    createdAt,
    tax,
    total,
    subTotal,
    fees,
    shipping,
    currency,
    country,
    locality,
    region,
    postalCode,
    familyName,
    givenName,
    address1,
    address2,
    paymentMethod,
  } = data;
  const { comicDetails, images } = product ?? {};
  const { title, grade, gradingAuthority, number } = comicDetails ?? {};

  const imageEdges = images?.edges ?? [];
  const imageUrls = imageEdges.map(({ node }) => node.url);

  const productTitle = `${getProductTitle({
    number: number ?? "",
    title: title ?? "",
  })} ${getProductGrade({ grade: grade ?? 0, gradingAuthority: t(`gradingAuthority.${kebabCase(gradingAuthority) ?? "CGC"}`) })}`;

  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext?.setTitle(
      t("product.document-title", {
        product: productTitle,
      }),
    );
  }, [productTitle, pageContext, t]);

  const handleProblemReportClick = () => {
    setIsReportProblemModalOpen(true);
  };

  return (
    <div>
      <SBBackButton
        label={t("common.buttons.back")}
        style={styles.backButton}
        onClick={onClose}
      />
      <div>
        <div {...stylex.props(styles.carousel)}>
          <SBImageCarousel imageUrls={imageUrls} />
        </div>
        <div>
          <SBHeading level={HeadingLevel.H2}>
            {t("order-details.order-number", { surrogateId })}
          </SBHeading>
        </div>
        <div {...stylex.props(styles.heading)}>
          <SBHeading level={HeadingLevel.H3}>
            {t("order-details.tracking")}
          </SBHeading>
        </div>
        <div>{shippingLabel?.trackingId}</div>
        <div {...stylex.props(styles.heading)}>
          <SBHeading level={HeadingLevel.H3}>
            {t("order-details.details")}
          </SBHeading>
        </div>
        <div {...stylex.props(styles.detailsRow)}>
          <div>{t("order-details.date")}</div>
          <div {...stylex.props(styles.rightColumn)}>
            <SBDate dateString={createdAt} format={DateFormatType.Short} />
          </div>
        </div>
        <div {...stylex.props(styles.detailsRow)}>
          <div>{t("order-details.subtotal")}</div>
          <div {...stylex.props(styles.rightColumn)}>
            {formatMoney(subTotal, currency, i18n.language, true)}
          </div>
        </div>
        <div {...stylex.props(styles.detailsRow)}>
          <div>{t("order-details.tax")}</div>
          <div {...stylex.props(styles.rightColumn)}>
            {formatMoney(tax, currency, i18n.language, true)}
          </div>
        </div>
        <div {...stylex.props(styles.detailsRow)}>
          <div>{t("order-details.shipping")}</div>
          <div {...stylex.props(styles.rightColumn)}>
            {formatMoney(shipping, currency, i18n.language, true)}
          </div>
        </div>
        <div {...stylex.props(styles.detailsRow)}>
          <div>{t("order-details.fees")}</div>
          <div {...stylex.props(styles.rightColumn)}>
            {formatMoney(fees ?? 0, currency, i18n.language, true)}
          </div>
        </div>
        <div {...stylex.props(styles.detailsRow)}>
          <div>{t("order-details.total")}</div>
          <div {...stylex.props(styles.rightColumn)}>
            {formatMoney(total, currency, i18n.language, true)}
          </div>
        </div>
      </div>
      <div {...stylex.props(styles.heading)}>
        <SBHeading level={HeadingLevel.H3}>
          {t("order-details.shipping-address")}
        </SBHeading>
      </div>
      <div>
        {givenName} {familyName}
      </div>
      <div>{address1}</div>
      {address2 ? <div>{address2}</div> : null}
      <div>
        {t("order-details.address-formatted", { locality, postalCode, region })}
      </div>
      <div>{country}</div>
      <div {...stylex.props(styles.heading)}>
        <SBHeading level={HeadingLevel.H3}>
          {t("order-details.payment-information")}
        </SBHeading>
      </div>
      <div>{t(`paymentMethod.${kebabCase(paymentMethod)}`)}</div>
      <div {...stylex.props(styles.heading)}>
        <SBHeading level={HeadingLevel.H3}>
          {t("order-details.purchase-details")}
        </SBHeading>
      </div>
      <div {...stylex.props(styles.detailsRow)}>
        <div>
          <SBImage
            alt=""
            height={200}
            src={images?.edges[0]?.node?.url ?? ""}
          />
        </div>
        <div>
          <div {...stylex.props(styles.rightColumn)}>
            {t("order-details.comic-details1", { number, title })}
          </div>
          <div {...stylex.props(styles.rightColumn)}>
            {t("order-details.comic-details2", {
              grade,
              gradingAuthority,
            })}
          </div>
        </div>
      </div>
      <div {...stylex.props(styles.actions)}>
        <SBButton
          title={t("order-details.report-problem")}
          onClick={handleProblemReportClick}
        />
      </div>
      <ReportProblemModal
        isOpen={isReportProblemModalOpen}
        orderId={orderId}
        setOpen={setIsReportProblemModalOpen}
        onSuccess={() => {
          setIsReportProblemModalOpen(false);
        }}
      />
    </div>
  );
};

const styles = stylex.create({
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 12,
    padding: 10,
  },
  backButton: {
    marginBottom: 40,
  },
  carousel: {
    marginBottom: 24,
    position: "relative",
  },
  detailsRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    justifyContent: "space-between",
  },
  heading: {
    borderColor: colors.topNavigationMenuBorderColor,
    borderTopStyle: "solid",
    borderTopWidth: 1,
    marginBottom: 20,
    marginTop: 40,
    paddingTop: 10,
  },
  rightColumn: {
    display: "flex",
    justifyContent: "end",
  },
});

export default OrderDetailView;
