/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesShopQuery } from "src/types/__generated__/QueriesShopQuery.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import { SBErrorBoundary, SBFallback } from "src/sbxui";

import ShopContent from "./ShopContent";
import ShopSearch from "./ShopSearch";

const Shop = (): React.ReactNode => {
  const { t } = useTranslation();

  const pageContext = useContext(PageContext);

  const queryRef = useLoaderData() as PreloadedQuery<QueriesShopQuery>;

  useEffect(() => {
    pageContext?.setTitle(t("shop.document-title"));
  }, [t, pageContext]);

  if (queryRef == null) {
    return null;
  }

  return (
    <>
      <div {...stylex.props(styles.search)}>
        <ShopSearch />
      </div>
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <ShopContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </>
  );
};

const styles = stylex.create({
  search: {
    marginBottom: 40,
  },
});

export default Shop;
