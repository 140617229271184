/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductDetailView_product$key } from "src/types/__generated__/ProductDetailView_product.graphql";
import type { RelatedProducts_relatedProducts$key } from "src/types/__generated__/RelatedProducts_relatedProducts.graphql";
import type { SimilarProducts_similarProducts$key } from "src/types/__generated__/SimilarProducts_similarProducts.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import CensusTable from "src/app/components/census/CensusTable";
import HistoricalSalesTable from "src/app/components/historical-sales/HistoricalSalesTable";
import { PageContext } from "src/app/context/page";
import { UserContext } from "src/app/context/user";
import { getProductGrade, getProductTitle } from "src/app/utils/product.util";
import { useFavorite, useUnfavorite } from "src/hooks";
import { SBFavoriteButton, SBImageCarousel } from "src/sbxui";

import ProductDetailHeader from "./ProductDetailHeader";
import ProductDetailMeta from "./ProductDetailMeta";
import RelatedProducts from "./RelatedProducts";
import SimilarProducts from "./SimilarProducts";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  product: ProductDetailView_product$key;
  queryKey: RelatedProducts_relatedProducts$key &
    SimilarProducts_similarProducts$key;
}>;

const ProductDetailView = ({ product, queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ProductDetailView_product on Product {
        ...ProductDetailHeader_product
        ...HistoricalSalesTable_product
        ...ProductDetailMeta_product
        id
        hasViewerFavorited
        createdAt
        updatedAt
        images {
          edges {
            node {
              url(quality: 100, webp: true, width: 1000)
            }
          }
        }
        comicDetails {
          ...CensusTable_comicDetail
          title
          number
          grade
          gradingAuthority
        }
      }
    `,
    product,
  );

  const { comicDetails, images, hasViewerFavorited, id } = data;
  const { title, grade, gradingAuthority, number } = comicDetails ?? {};

  const productTitle = `${getProductTitle({
    number: number ?? "",
    title: title ?? "",
  })} ${getProductGrade({ grade: grade ?? 0, gradingAuthority: t(`gradingAuthority.${kebabCase(gradingAuthority) ?? "UNKNOWN"}`) })}`;

  const imageEdges = images?.edges ?? [];
  const imageUrls = imageEdges.map(({ node }) => node.url);

  const pageContext = useContext(PageContext);
  const userContext = useContext(UserContext);

  const [commitFavorite, isInFlightFavorite] = useFavorite();
  const [commitUnfavorite, isInFlightUnfavorite] = useUnfavorite();

  const handleClickFavorite = useCallback(() => {
    if (isInFlightFavorite || isInFlightUnfavorite) {
      return;
    }
    const variables = {
      input: {
        productId: id,
      },
    };
    hasViewerFavorited
      ? commitUnfavorite({
          optimisticResponse: {
            unfavorite: {
              hasViewerFavorited: false,
              id,
            },
          },
          variables,
        })
      : commitFavorite({
          optimisticResponse: {
            favorite: {
              hasViewerFavorited: true,
              id,
            },
          },
          variables,
        });
  }, [
    commitFavorite,
    commitUnfavorite,
    hasViewerFavorited,
    isInFlightFavorite,
    isInFlightUnfavorite,
    id,
  ]);

  useEffect(() => {
    pageContext?.setTitle(
      t("product.document-title", {
        product: productTitle,
      }),
    );
  }, [productTitle, pageContext, t]);

  const showFavoriteButton = userContext.user != null;

  return (
    <div {...stylex.props(styles.product)}>
      <div {...stylex.props(styles.columns)}>
        <div {...stylex.props(styles.columnLeftMobile)}>
          <ProductDetailHeader queryKey={data} />
          <div {...stylex.props(styles.carousel)}>
            <SBImageCarousel imageUrls={imageUrls} />
            {showFavoriteButton ? (
              <div {...stylex.props(styles.favorite)}>
                <SBFavoriteButton
                  favorite={hasViewerFavorited}
                  loading={isInFlightFavorite || isInFlightUnfavorite}
                  number={number ?? ""}
                  style={styles.favorite}
                  title={title ?? ""}
                  onClick={handleClickFavorite}
                />
              </div>
            ) : null}
          </div>
          <ProductDetailMeta queryKey={data} />
        </div>
        <div {...stylex.props(styles.columnRightMobile)}>
          {data ? <HistoricalSalesTable queryKey={data} /> : null}
          {comicDetails ? <CensusTable queryKey={comicDetails} /> : null}
        </div>
        <div {...stylex.props(styles.columnLeft)}>
          <div {...stylex.props(styles.carousel)}>
            <SBImageCarousel imageUrls={imageUrls} />
            {showFavoriteButton ? (
              <div {...stylex.props(styles.favorite)}>
                <SBFavoriteButton
                  favorite={hasViewerFavorited}
                  loading={isInFlightFavorite || isInFlightUnfavorite}
                  number={number ?? ""}
                  style={styles.favorite}
                  title={title ?? ""}
                  onClick={handleClickFavorite}
                />
              </div>
            ) : null}
          </div>
          <ProductDetailMeta queryKey={data} />
        </div>
        <div {...stylex.props(styles.columnRight)}>
          <ProductDetailHeader queryKey={data} />
          {data ? <HistoricalSalesTable queryKey={data} /> : null}
          {comicDetails ? <CensusTable queryKey={comicDetails} /> : null}
        </div>
      </div>
      <SimilarProducts queryKey={queryKey} />
      <RelatedProducts queryKey={queryKey} />
    </div>
  );
};

const styles = stylex.create({
  backButton: {
    marginBottom: 40,
  },
  carousel: {
    marginBottom: 24,
    position: "relative",
  },
  census: {
    marginTop: 40,
  },
  censusTable: {
    marginTop: 10,
  },
  columnLeft: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "grid",
      default: "block",
    },
    gridArea: {
      default: "1 / 1 / 2 / 6",
    },
    marginBottom: 24,
  },
  columnLeftMobile: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "none",
      default: "none",
    },
  },
  columnRight: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "block",
      default: "block",
    },
    gridArea: "1 / 7 / 2 / 11",
    marginBottom: 24,
  },
  columnRightMobile: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "none",
      default: "none",
    },
  },
  columns: {
    display: "grid",
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(10, 1fr)",
      default: "repeat(10, 1fr)",
    },
    margin: 0,
  },
  favorite: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  product: {
    marginBottom: 96,
  },
});

export default ProductDetailView;
