/**
 * @generated SignedSource<<14484c940b130cca591c1921432782ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductFavoriteInput = {
  productId: string;
};
export type useFavoriteMutation$variables = {
  input: ProductFavoriteInput;
};
export type useFavoriteMutation$data = {
  readonly favorite: {
    readonly " $fragmentSpreads": FragmentRefs<"ProductDetailView_product" | "SearchResultCard_product">;
  };
};
export type useFavoriteMutation = {
  response: useFavoriteMutation$data;
  variables: useFavoriteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "favoriteInput",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "saleDate": "DESC"
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useFavoriteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "favorite",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SearchResultCard_product"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductDetailView_product"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useFavoriteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "favorite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasViewerFavorited",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ComicDetail",
            "kind": "LinkedField",
            "name": "comicDetails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grade",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gradingAuthority",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "year",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publisher",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "keyComments",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "artComments",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "barcode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categoryRollup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gradeText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publicGraderNotes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pedigree",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "variant",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pageQuality",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signatures",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "specialCopy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "census",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ImagesConnection",
            "kind": "LinkedField",
            "name": "images",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ImagesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "quality",
                            "value": 100
                          },
                          {
                            "kind": "Literal",
                            "name": "webp",
                            "value": true
                          },
                          {
                            "kind": "Literal",
                            "name": "width",
                            "value": 1000
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": "url(quality:100,webp:true,width:1000)"
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValueScore",
            "kind": "LinkedField",
            "name": "fmvScore",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxValue",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rank",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "HistoricalSalesConnection",
            "kind": "LinkedField",
            "name": "historicalSales",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HistoricalSalesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HistoricalSale",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "source",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "saleDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "saleFormat",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "historicalSales(first:5,orderBy:{\"saleDate\":\"DESC\"})"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": [
              "orderBy"
            ],
            "handle": "connection",
            "key": "HistoricalSalesTable_historicalSales",
            "kind": "LinkedHandle",
            "name": "historicalSales"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "submissionNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GradedCopy",
            "kind": "LinkedField",
            "name": "gradedCopy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slabDefects",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPublic",
            "kind": "LinkedField",
            "name": "seller",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shopName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shopUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "545117815d0e6a27c63cf9fb6484c0a1",
    "id": null,
    "metadata": {},
    "name": "useFavoriteMutation",
    "operationKind": "mutation",
    "text": "mutation useFavoriteMutation(\n  $input: ProductFavoriteInput!\n) {\n  favorite(favoriteInput: $input) {\n    ...SearchResultCard_product\n    ...ProductDetailView_product\n    id\n  }\n}\n\nfragment CensusTable_comicDetail on ComicDetail {\n  census\n}\n\nfragment HistoricalSalesTable_product on Product {\n  comicDetails {\n    grade\n    gradingAuthority\n    id\n  }\n  historicalSales(first: 5, orderBy: {saleDate: DESC}) {\n    edges {\n      node {\n        id\n        source\n        saleDate\n        saleFormat\n        price\n        currency\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment ProductDetailHeader_product on Product {\n  id\n  price\n  currency\n  comicDetails {\n    title\n    number\n    id\n  }\n  images {\n    edges {\n      node {\n        url(quality: 100, webp: true, width: 1000)\n        id\n      }\n    }\n  }\n  fmvScore {\n    minValue\n    maxValue\n    currency\n    rank\n  }\n}\n\nfragment ProductDetailMeta_product on Product {\n  submissionNotes\n  comicDetails {\n    grade\n    gradingAuthority\n    year\n    publisher\n    keyComments\n    artComments\n    barcode\n    categoryRollup\n    gradeText\n    publicGraderNotes\n    pedigree\n    variant\n    pageQuality\n    signatures\n    specialCopy\n    id\n  }\n  gradedCopy {\n    slabDefects\n    id\n  }\n  seller {\n    shopName\n    shopUrl\n  }\n}\n\nfragment ProductDetailView_product on Product {\n  ...ProductDetailHeader_product\n  ...HistoricalSalesTable_product\n  ...ProductDetailMeta_product\n  id\n  hasViewerFavorited\n  createdAt\n  updatedAt\n  images {\n    edges {\n      node {\n        url(quality: 100, webp: true, width: 1000)\n        id\n      }\n    }\n  }\n  comicDetails {\n    ...CensusTable_comicDetail\n    title\n    number\n    grade\n    gradingAuthority\n    id\n  }\n}\n\nfragment SearchResultCard_product on Product {\n  hasViewerFavorited\n}\n"
  }
};
})();

(node as any).hash = "9a2ac95d5688cfe64aaadbe1527bda60";

export default node;
