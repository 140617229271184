/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SelectInputOption } from "src/sbxui";
import type { AccountAddressesView_user$key } from "src/types/__generated__/AccountAddressesView_user.graphql";
import type {
  AccountAddressesViewCreateAddressMutation,
  Country,
} from "src/types/__generated__/AccountAddressesViewCreateAddressMutation.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { defaultRules, useValidation } from "react-simple-form-validator";

import { UserContext } from "src/app/context/user";
import { useDefaultValidationErrorMessages } from "src/hooks";
import {
  HeadingLevel,
  SBButton,
  SBCheckboxInput,
  SBHeading,
  SBModal,
  SBSelectInput,
  SBTextInput,
} from "src/sbxui";
import { isValidPostalCode } from "src/utils";

import localitiesUs from "./_data/localities-US.json";
import regionsCa from "./_data/regions-CA.json";
import regionsUs from "./_data/regions-US.json";
import AccountAddressCard from "./AccountAddressCard";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const NAME_RE = /^[A-Za-z\u00C0-\u017F\u1E00-\u1EFF '\u2019-]+(?:.)?$/u;

const POSTAL_CODE_MAX_LENGTH = 10;

const ALLOWED_ADDRESS_COUNTRIES: SelectInputOption[] = [
  { id: "US", label: "United States" },
  { id: "CA", label: "Canada" },
  { id: "GB", label: "United Kingdom" },
];

const US_MILITARY_REGIONS = ["AA", "AE", "AP"];

type Props = Readonly<{
  queryKey: AccountAddressesView_user$key;
}>;

const AccountAddressesView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const userContext = useContext(UserContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [addressName, setAddressName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [country, setCountry] = useState<SelectInputOption>(
    ALLOWED_ADDRESS_COUNTRIES[0],
  );

  let regionPlaceholder = t("account.addresses.fields.state");
  let regions = regionsUs.map((code) => ({
    id: code,
    label: t(`regions.us.${kebabCase(code)}`),
  }));
  if (country.id === "CA") {
    regionPlaceholder = t("account.addresses.fields.province");
    regions = regionsCa.map((code) => ({
      id: code,
      label: t(`regions.ca.${kebabCase(code)}`),
    }));
  } else if (country.id === "GB") {
    regionPlaceholder = t("account.addresses.fields.country");
    regions = [];
  }
  const defaultRegion = {
    id: "placeholder",
    label: regionPlaceholder,
  };
  const [region, setRegion] = useState(defaultRegion);

  const localities = localitiesUs.map((code) => ({ id: code, label: code }));

  const defaultLocality =
    country.id === "US" && US_MILITARY_REGIONS.includes(region.id)
      ? localities[0]
      : {
          id: "placeholder",
          label: t("account.addresses.fields.locality"),
        };
  const [locality, setLocality] = useState(defaultLocality);

  let postalCodeError = t("account.addresses.errors.postal-code");
  let postalCodeErrorField = t("account.addresses.errors.labels.postal-code");
  let postalCodePlaceholder = t("account.addresses.fields.postal-code");
  if (country.id === "US") {
    postalCodeError = t("account.addresses.errors.zip-code");
    postalCodeErrorField = t("account.addresses.errors.labels.zip-code");
    postalCodePlaceholder = t("account.addresses.fields.zip-code");
  } else if (country.id === "CA") {
    postalCodeError = t("account.addresses.errors.postcode");
    postalCodeErrorField = t("account.addresses.errors.labels.postcode");
    postalCodePlaceholder = t("account.addresses.fields.postcode");
  }
  const [postalCode, setPostalCode] = useState("");

  const user = useFragment(
    graphql`
      fragment AccountAddressesView_user on User {
        addresses(
          first: 20
          orderBy: { isDefault: DESC }
          where: { type: SHIPPING }
        ) {
          __id
          edges {
            node {
              id
              ...AccountAddressCard_address
            }
          }
        }
      }
    `,
    queryKey,
  );

  const addresses = user?.addresses?.edges ?? [];

  const [isDefault, setIsDefault] = useState(addresses.length < 1);

  const [commit, isInFlight] =
    useMutation<AccountAddressesViewCreateAddressMutation>(graphql`
      mutation AccountAddressesViewCreateAddressMutation(
        $input: CreateAddressInput!
        $connections: [ID!]!
      ) {
        createAddress(createAddressInput: $input)
          @appendNode(
            connections: $connections
            edgeTypeName: "AddressesEdge"
          ) {
          ...AccountAddressCard_address
        }
      }
    `);

  const [touchedFields, setTouchedFields] = useState({
    address1: false,
    addressName: false,
    familyName: false,
    givenName: false,
    locality: false,
    postalCode: false,
  });

  const defaultValidationErrorMessages = useDefaultValidationErrorMessages();

  const [isRegionValid, setIsRegionValid] = useState(true);
  const { getErrorsInField, isFieldInError, isFormValid } = useValidation({
    fieldsRules: {
      address1: { required: true },
      addressName: { required: true },
      familyName: { customNameRule: true, required: true },
      givenName: { customNameRule: true, required: true },
      locality: { required: true },
      postalCode: { customPostalCodeRule: country.id, required: true },
    },
    labels: {
      address1: t("account.addresses.errors.labels.address1"),
      addressName: t("account.addresses.errors.labels.address-name"),
      familyName: t("signup.errors.labels.family-name"),
      givenName: t("signup.errors.labels.given-name"),
      locality: t("account.addresses.errors.labels.locality"),
      postalCode: postalCodeErrorField,
    },
    messages: {
      // We have our own library for localization, so we just use en here.
      en: {
        ...defaultValidationErrorMessages,
        customNameRule: t("signup.errors.name"),
        customPostalCodeRule: postalCodeError,
      },
    },
    rules: {
      ...defaultRules,
      customNameRule: NAME_RE,
      customPostalCodeRule: isValidPostalCode,
    },
    state: {
      address1,
      addressName,
      familyName,
      givenName,
      locality,
      postalCode,
    },
  });

  const reset = () => {
    setAddressName("");
    setGivenName("");
    setFamilyName("");
    setAddress1("");
    setAddress2("");
    setRegion(defaultRegion);
    setLocality(defaultLocality);
    setPostalCode("");
    setCountry(ALLOWED_ADDRESS_COUNTRIES[0]);
  };

  const handleCreateAddress = () => {
    if (isInFlight) {
      return;
    }
    setIsRegionValid(region.id !== "placeholder");
    setTouchedFields({
      address1: !isFormValid,
      addressName: !isFormValid,
      familyName: !isFormValid,
      givenName: !isFormValid,
      locality: !isFormValid,
      postalCode: !isFormValid,
    });
    if (!isFormValid || !isRegionValid) {
      return;
    }
    commit({
      onCompleted() {
        setIsModalOpen(false);
        reset();
      },
      onError(_error) {
        // not sure what to do here
      },
      variables: {
        connections: [user?.addresses?.__id ?? ""],
        input: {
          address1,
          address2: address2 === "" ? null : address2,
          country: country.id as Country,
          familyName,
          givenName,
          isDefault,
          locality: locality.id === "placeholder" ? "" : locality.id,
          name: addressName,
          postalCode,
          region: region.id === "placeholder" ? "" : region.id,
          type: "SHIPPING",
          userId: userContext?.user?.userId ?? "",
        },
      },
    });
  };

  const handleBlurField = (
    _event: React.FocusEvent<HTMLInputElement>,
    field: string,
  ) => {
    setTouchedFields((prevFields) => ({ ...prevFields, [field]: true }));
  };

  const handleOnChangeIsDefault = () => {
    setIsDefault(!isDefault);
  };

  let regionErrorMessage = t("account.addresses.errors.region.select");
  if (country.id === "CA") {
    regionErrorMessage = t("account.addresses.errors.province.select");
  } else if (country.id === "GB") {
    regionErrorMessage = t("account.addresses.errors.country.select");
  } else if (country.id === "US") {
    regionErrorMessage = t("account.addresses.errors.state.select");
  }

  let regionInput = (
    <SBTextInput
      errorMessage={!isRegionValid && regionErrorMessage}
      id="region"
      label={regionPlaceholder}
      placeholder={regionPlaceholder}
      style={styles.formField}
      value={region.id === "placeholder" ? "" : region.id}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setRegion({
          id: event.currentTarget.value,
          label: event.currentTarget.value,
        });
      }}
    />
  );
  if (country.id === "CA" || country.id === "US") {
    regionInput = (
      <SBSelectInput
        errorMessage={!isRegionValid && regionErrorMessage}
        options={regions}
        placeholder={regionPlaceholder}
        style={styles.formField}
        value={region}
        onChange={(selected: string) => {
          const selectedOption = regions.find(
            ({ label }) => label === selected,
          );

          if (selectedOption) {
            if (
              country.id === "US" &&
              US_MILITARY_REGIONS.includes(selectedOption.id)
            ) {
              setLocality(localities[0]);
            }
            setRegion(selectedOption);
          }
        }}
      />
    );
  }

  let localityInput = (
    <SBTextInput
      errorMessage={
        touchedFields.locality
          ? isFieldInError("locality") && getErrorsInField("locality")[0]
          : null
      }
      id="locality"
      label={t("account.addresses.fields.locality")}
      placeholder={t("account.addresses.fields.locality")}
      style={styles.formField}
      value={locality.id === "placeholder" ? "" : locality.id}
      onBlur={(event) => {
        handleBlurField(event, "locality");
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setLocality({
          id: event.currentTarget.value,
          label: event.currentTarget.value,
        })
      }
    />
  );
  if (country.id === "US" && US_MILITARY_REGIONS.includes(region.id)) {
    localityInput = (
      <SBSelectInput
        errorMessage={!isRegionValid && regionErrorMessage}
        options={localities}
        style={styles.formField}
        value={locality}
        onChange={(selected: string) => {
          const selectedOption = localities.find(
            ({ label }) => label === selected,
          );

          if (selectedOption) {
            setLocality(selectedOption);
          }
        }}
      />
    );
  }

  return (
    <>
      <div {...stylex.props(styles.header)}>
        <SBHeading level={HeadingLevel.H2} style={styles.heading}>
          {t("account.nav.addresses")}
        </SBHeading>
        <SBButton
          disabled={isInFlight}
          title={t("account.addresses.buttons.create")}
          onClick={() => {
            reset();
            setIsRegionValid(true);
            setTouchedFields({
              address1: false,
              addressName: false,
              familyName: false,
              givenName: false,
              locality: false,
              postalCode: false,
            });
            setIsModalOpen(true);
          }}
        />
      </div>

      {addresses
        .filter(({ node }) => node != null)
        .map(({ node }) => (
          <AccountAddressCard key={node.id} queryKey={node} />
        ))}
      <SBModal
        footer={
          <div {...stylex.props(styles.modalFooter)}>
            <SBButton
              loading={isInFlight}
              title={t("account.addresses.buttons.save")}
              onClick={handleCreateAddress}
            />
          </div>
        }
        headerText={t("account.addresses.modal-header")}
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
      >
        <div {...stylex.props(styles.modal)}>
          <form method="POST">
            <SBTextInput
              disabled={isInFlight}
              errorMessage={
                touchedFields.addressName
                  ? isFieldInError("addressName") &&
                    getErrorsInField("addressName")[0]
                  : null
              }
              id="addressName"
              label={t("account.addresses.fields.address-name")}
              placeholder={t("account.addresses.fields.address-name")}
              style={styles.formField}
              value={addressName}
              onBlur={(event) => {
                handleBlurField(event, "addressName");
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAddressName(event.currentTarget.value)
              }
            />
            <SBTextInput
              errorMessage={
                touchedFields.givenName
                  ? isFieldInError("givenName") &&
                    getErrorsInField("givenName")[0]
                  : null
              }
              id="givenName"
              label={t("account.addresses.fields.given-name")}
              placeholder={t("account.addresses.fields.given-name")}
              style={styles.formField}
              value={givenName}
              onBlur={(event) => {
                handleBlurField(event, "givenName");
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setGivenName(event.currentTarget.value)
              }
            />
            <SBTextInput
              errorMessage={
                touchedFields.familyName
                  ? isFieldInError("familyName") &&
                    getErrorsInField("familyName")[0]
                  : null
              }
              id="familyName"
              label={t("account.addresses.fields.family-name")}
              placeholder={t("account.addresses.fields.family-name")}
              style={styles.formField}
              value={familyName}
              onBlur={(event) => {
                handleBlurField(event, "familyName");
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFamilyName(event.currentTarget.value)
              }
            />
            <SBSelectInput
              options={ALLOWED_ADDRESS_COUNTRIES}
              style={styles.formField}
              value={country}
              onChange={(selected: string) => {
                setAddress1("");
                setAddress2("");
                setRegion(defaultRegion);
                setLocality(defaultLocality);
                setPostalCode("");

                const selectedOption = ALLOWED_ADDRESS_COUNTRIES.find(
                  ({ label }) => label === selected,
                );

                if (selectedOption) {
                  setCountry(selectedOption);
                }
              }}
            />
            <SBTextInput
              errorMessage={
                touchedFields.address1
                  ? isFieldInError("address1") &&
                    getErrorsInField("address1")[0]
                  : null
              }
              id="address1"
              label={t("account.addresses.fields.address1")}
              placeholder={t("account.addresses.fields.address1")}
              style={styles.formField}
              value={address1}
              onBlur={(event) => {
                handleBlurField(event, "address1");
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAddress1(event.currentTarget.value)
              }
            />
            <SBTextInput
              id="address2"
              label={t("account.addresses.fields.address2")}
              placeholder={t("account.addresses.fields.address2")}
              style={styles.formField}
              value={address2}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAddress2(event.currentTarget.value)
              }
            />
            {localityInput}
            <div {...stylex.props(styles.lastRow)}>
              {regionInput}
              <SBTextInput
                errorMessage={
                  touchedFields.postalCode
                    ? isFieldInError("postalCode") &&
                      getErrorsInField("postalCode")[0]
                    : null
                }
                id="postalCode"
                label={postalCodePlaceholder}
                maxLength={POSTAL_CODE_MAX_LENGTH}
                placeholder={postalCodePlaceholder}
                style={styles.formField}
                value={postalCode}
                onBlur={(event) => {
                  handleBlurField(event, "postalCode");
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPostalCode(event.currentTarget.value)
                }
              />
            </div>
            {addresses.length > 0 && (
              <SBCheckboxInput
                checked={isDefault}
                id="isDefault"
                style={styles.checkbox}
                onChange={handleOnChangeIsDefault}
              >
                {t("account.addresses.make-default")}
              </SBCheckboxInput>
            )}
          </form>
        </div>
      </SBModal>
    </>
  );
};

const styles = stylex.create({
  checkbox: {
    marginTop: 4,
  },
  formField: {
    marginBottom: 8,
  },
  header: {
    alignItems: "center",
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(3, 1fr)",
      default: "repeat(4, 1fr)",
    },
    justifyContent: "space-between",
    marginBottom: 12,
  },
  heading: {
    fontSize: 24,
    gridColumn: "span 3 / span 3",
  },
  lastRow: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "repeat(2, 1fr)",
    justifyContent: "space-between",
  },
  modal: {
    padding: 16,
  },
  modalFooter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default AccountAddressesView;
