/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import graphql from "babel-plugin-relay/macro";

export const HomeQuery = graphql`
  query QueriesHomeQuery($vertical: Vertical!) {
    ...HomeView_verticalSearch @arguments(vertical: $vertical)
  }
`;

export const AccountQuery = graphql`
  query QueriesAccountQuery {
    viewer {
      user {
        ...AccountPersonalInfoView_user
        ...AccountOrdersView_user
        ...AccountFavoritesView_user
        ...AccountOffersView_user
        ...AccountInvoicesView_user
        ...AccountAddressesView_user
      }
    }
  }
`;

export const AuctionSearchQuery = graphql`
  query QueriesAuctionSearchQuery {
    ...AuctionSearchView_auctionSearch
  }
`;

export const AuctionQuery = graphql`
  query QueriesAuctionQuery($id: ID!) {
    node(id: $id) {
      ... on Auction {
        ...AuctionView_auction
      }
    }
  }
`;

export const AuctionLotQuery = graphql`
  query QueriesAuctionLotQuery($id: ID!) {
    viewer {
      user {
        ...AuctionLotView_user
      }
    }
    node(id: $id) {
      ... on AuctionLot {
        ...AuctionLotView_auctionLot
      }
    }
  }
`;

export const ShopQuery = graphql`
  query QueriesShopQuery($vertical: Vertical!) {
    ...ShopView_verticalSearch @arguments(vertical: $vertical)
  }
`;

export const ProductDetailQuery = graphql`
  query QueriesProductDetailQuery($id: ID!, $productId: String!) {
    node(id: $id) {
      ... on Product {
        ...ProductDetailView_product
      }
    }
    ...SimilarProducts_similarProducts
    ...RelatedProducts_relatedProducts
  }
`;

export const OrderDetailQuery = graphql`
  query QueriesOrderDetailQuery($id: Int!) {
    orderBySurrogateId(surrogateId: $id) {
      ... on Order {
        ...OrderDetailView_order
      }
    }
  }
`;

export const ArticlesQuery = graphql`
  query QueriesArticlesQuery {
    ...ArticlesViewQuery_articles
  }
`;

export const ArticleDetailQuery = graphql`
  query QueriesArticleDetailQuery($slug: String!) {
    articleBySlug(slug: $slug) {
      ...ArticleDetailView_article
    }
    ...ArticlesRecent_articles
  }
`;

export const FaqsQuery = graphql`
  query QueriesFaqsQuery {
    ...FaqsViewQuery_faqs
  }
`;

export const PrivacyQuery = graphql`
  query QueriesPrivacyQuery {
    privacy {
      ...PrivacyView_document
    }
  }
`;

export const TermsQuery = graphql`
  query QueriesTermsQuery {
    terms {
      ...TermsView_document
    }
  }
`;

export const WhyUseShortboxedQuery = graphql`
  query QueriesWhyUseShortboxedQuery {
    node(id: "RG9jdW1lbnQ6MWYxN2Q5ZGUtMmY0OS00NDFjLThmYWItNjUzNDBlODM5MzVh") {
      ... on Document {
        ...DocumentView_document
      }
    }
  }
`;

export const ContactUsQuery = graphql`
  query QueriesContactUsQuery {
    node(id: "RG9jdW1lbnQ6MjFhODNlMTUtYjczZi00ZDAyLTlkZWYtMzg0ZDg1Y2QwNDE0") {
      ... on Document {
        ...DocumentView_document
      }
    }
  }
`;

export const CareersQuery = graphql`
  query QueriesCareersQuery {
    node(id: "RG9jdW1lbnQ6ODQ4MGIxYzAtZjAyMC00NDdmLThiMmItNDE5MTQ4ZjI0Y2Zh") {
      ... on Document {
        ...DocumentView_document
      }
    }
  }
`;
