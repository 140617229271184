/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useContext, useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { VerticalSearchContext } from "src/app/context/vertical-search";
import { SELLER_PATH, SHOP_PATH } from "src/app/router/Router";
import { SBActivityIndicator, SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { getContextFromShopParams, getUrlFromShopSearch } from "src/utils";

import ShopFilterModal from "./ShopFilterModal";
import ShopSortModal from "./ShopSortModal";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const ShopSearch = React.memo(() => {
  const { t } = useTranslation();

  const { shopUrl } = useParams();

  const {
    categoryRollups,
    gradeMax,
    gradeMin,
    gradingAuthorities,
    isSearching,
    priceMax,
    priceMin,
    sort,
    specialCopies,
    setSearchTerm,
    searchTerm,
  } = useContext(VerticalSearchContext);

  const [isPending, startTransition] = useTransition();

  const [term, setTerm] = useState(searchTerm);
  const [isShopSortModalOpen, setIsShopSortModalOpen] = useState(false);
  const [isShopFilterModalOpen, setIsShopFilterModalOpen] = useState(false);

  const { pathname } = useLocation();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(event.currentTarget.value);
  };

  const handleSearchSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    startTransition(() => {
      setSearchTerm(term);
      history.pushState(
        {},
        "",
        getUrlFromShopSearch({ searchTerm: term, sort }),
      );
    });
  };

  const handleClickClear = (_e: React.SyntheticEvent) => {
    setTerm("");
    startTransition(() => {
      setSearchTerm("");
      history.pushState({}, "", getUrlFromShopSearch({ searchTerm: "", sort }));
    });
  };

  const handleSortClick = (_e: React.SyntheticEvent) => {
    setIsShopSortModalOpen(true);
    setIsShopFilterModalOpen(false);
  };

  const handleFilterClick = (_e: React.SyntheticEvent) => {
    setIsShopSortModalOpen(false);
    setIsShopFilterModalOpen(true);
  };

  let count =
    categoryRollups.length + gradingAuthorities.length + specialCopies.length;
  if (gradeMax != null || gradeMin != null) {
    count += 1;
  }
  if (priceMax != null || priceMin != null) {
    count += 1;
  }

  useEffect(() => {
    if (pathname.startsWith(SHOP_PATH)) {
      const context = getContextFromShopParams();
      setTerm(context.termParam);
      startTransition(() => {
        setSearchTerm(context.termParam);
      });
    } else if (pathname.startsWith(SELLER_PATH.replace(":shopUrl", ""))) {
      setTerm(searchTerm);
      startTransition(() => {
        setSearchTerm(searchTerm);
      });
    }

    const handlePopState = () => {
      const popContext = getContextFromShopParams();
      setTerm(popContext.termParam);
      startTransition(() => {
        setSearchTerm(popContext.termParam);
      });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [pathname, searchTerm, setSearchTerm, shopUrl]);

  return (
    <div {...stylex.props(styles.root)}>
      <form
        {...stylex.props(auto, styles.form)}
        role="search"
        onSubmit={handleSearchSubmit}
      >
        <input
          {...stylex.props(auto, styles.input)}
          id="search"
          placeholder={t("search.placeholder")}
          type="text"
          value={term}
          onChange={handleSearchChange}
        />
        {term !== "" && (
          <button
            {...stylex.props(auto, styles.clear)}
            aria-label={t("search.clearLabel")}
            type="button"
            onClick={handleClickClear}
          >
            <SBIcon fill={false} icon="cancel" style={styles.cancel} />
          </button>
        )}
        <button
          {...stylex.props(auto, styles.button)}
          aria-label={t("search.button-label")}
          disabled={isSearching || isPending}
          type="submit"
        >
          {isSearching || isPending ? (
            <SBActivityIndicator small={true} style={styles.spinner} />
          ) : (
            <SBIcon icon="search" style={styles.icon} />
          )}
        </button>
      </form>
      <div {...stylex.props(auto, styles.filterSort)}>
        <button
          {...stylex.props(auto, styles.sort)}
          aria-label={t("search.sort")}
          type="button"
          onClick={handleSortClick}
        >
          <span {...stylex.props(styles.sortLabel)}>{t("search.sort")}</span>
          <SBIcon
            aria-hidden="true"
            fill={false}
            icon="sort"
            style={styles.sortIcon}
          />
        </button>
        <button
          {...stylex.props(auto, styles.filter)}
          aria-label={t("search.filter", {
            count,
          })}
          type="button"
          onClick={handleFilterClick}
        >
          <span {...stylex.props(styles.filterLabel)}>
            {t("search.filter", {
              count,
            })}
          </span>
          <SBIcon
            aria-hidden="true"
            fill={false}
            icon="filter_list"
            style={styles.filterIcon}
          />
        </button>
      </div>
      <ShopSortModal
        isOpen={isShopSortModalOpen}
        setOpen={setIsShopSortModalOpen}
      />
      <ShopFilterModal
        isOpen={isShopFilterModalOpen}
        setOpen={setIsShopFilterModalOpen}
      />
    </div>
  );
});

const styles = stylex.create({
  button: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: colors.topNavigationSearchButtonBackgroundColor,
    borderRadius: 40,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
    width: 40,
  },
  cancel: {
    color: colors.topNavigationSearchClearColor,
  },
  clear: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
    width: 40,
  },
  filter: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  filterIcon: {
    color: colors.topNavigationSearchClearColor,
    marginLeft: 8,
  },
  filterLabel: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  filterSort: {
    alignItems: "center",
    display: "flex",
  },
  form: {
    alignItems: "center",
    backgroundColor: colors.topNavigationSearchBackgroundColor,
    borderColor: colors.topNavigationSearchBorderColor,
    borderRadius: 48,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: colors.topNavigationSearchShadow,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: 16,
    padding: 4,
    paddingLeft: 16,
    width: "100%",
  },
  icon: {
    color: colors.topNavigationSearchButtonColor,
  },
  input: {
    borderWidth: 0,
    height: 40,
    outline: {
      ":hover": "none",
      default: "none",
    },
    width: "100%",
  },
  root: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  sort: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  sortIcon: {
    color: colors.topNavigationSearchClearColor,
    marginLeft: 8,
  },
  sortLabel: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  spinner: {
    fill: colors.topNavigationSearchButtonColor,
  },
});

export default ShopSearch;
