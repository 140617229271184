/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { SBIcon, SBMarkdownRenderer } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  content: string;
  style?: StyleXStyles;
  title: string;
}>;

export const SBCollapsibleText = ({
  content,
  title,
  style,
}: Props): React.ReactNode | null => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(true);

  const onClickHeader = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  const count = content.length;

  if (count === 0) {
    return null;
  }

  return (
    <div {...stylex.props(style)}>
      <div {...stylex.props(auto, styles.container)}>
        <a
          {...stylex.props(styles.toggle, !expanded && styles.toggleCollapsed)}
          aria-expanded={expanded}
          aria-label={t(`common.menus.${expanded ? "collapse" : "expand"}`, {
            content: title,
          })}
          href="#"
          onClick={onClickHeader}
        >
          <p {...stylex.props(styles.title)}>{title}</p>
          {count > 0 && (
            <SBIcon
              icon={expanded ? "expand_less" : "expand_more"}
              style={styles.icon}
            />
          )}
        </a>
        {expanded ? (
          <div {...stylex.props(styles.description)}>
            <SBMarkdownRenderer markdown={content} />{" "}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const styles = stylex.create({
  container: {
    borderRadius: 8,
    borderStyle: "solid",
    color: colors.color,
    fontSize: 14,
  },
  description: {
    margin: 20,
  },
  icon: {
    color: colors.color,
  },
  title: {
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 24,
    lineHeight: 0.875,
    margin: 0,
    textTransform: "uppercase",
  },
  toggle: {
    alignItems: "center",
    borderBottomStyle: "solid",
    borderColor: colors.color,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderWidth: 1,
    color: {
      ":hover": colors.color,
      default: colors.color,
    },
    display: "flex",
    justifyContent: "space-between",
    paddingBlock: 12,
    paddingInline: 16,
    textDecorationLine: "none",
  },
  toggleCollapsed: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomStyle: "none",
  },
});
