/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ArticleDetailView_article$key } from "src/types/__generated__/ArticleDetailView_article.graphql";
import type { ArticlesRecent_articles$key } from "src/types/__generated__/ArticlesRecent_articles.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import { PageContext } from "src/app/context/page";
import {
  HeadingLevel,
  SBHeading,
  SBImage,
  SBLink,
  SBMarkdownRenderer,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import ArticlesRecent from "./ArticlesRecent";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const SHARE_BASE_URL = "https://shortboxed.com/blog/";

type Props = Readonly<{
  article: ArticleDetailView_article$key;
  queryKey: ArticlesRecent_articles$key;
}>;

const ArticleDetailView = ({ article, queryKey }: Props): React.ReactNode => {
  const pageContext = useContext(PageContext);
  const { i18n, t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ArticleDetailView_article on Article {
        id
        createdAt
        updatedAt
        publishedAt
        summary
        authorEnum
        avatarUrl
        image
        imageAlt
        title
        body
        slug
      }
    `,
    article,
  );

  const {
    id,
    authorEnum,
    avatarUrl,
    body,
    image,
    imageAlt,
    publishedAt,
    slug,
    summary,
    title,
  } = data;

  const publishedAtDate =
    publishedAt == null
      ? publishedAt
      : new Date(
          Number.isNaN(Number(publishedAt)) ? publishedAt : Number(publishedAt),
        );

  const publishedOn =
    publishedAtDate == null
      ? null
      : publishedAtDate.toLocaleDateString(i18n.language, {
          day: "numeric",
          month: "short",
          year: "numeric",
        });

  useEffect(() => {
    pageContext?.setTitle(title);
  }, [title, pageContext, image, summary]);

  return (
    <>
      <article {...stylex.props(styles.article)}>
        <SBHeading level={HeadingLevel.H1} style={styles.heading}>
          {title}
        </SBHeading>
        <SBHeading
          aria-label={
            publishedOn == null
              ? ""
              : t("blog.published-on", {
                  date: publishedOn,
                })
          }
          level={HeadingLevel.H2}
          style={styles.date}
        >
          {publishedOn != null && publishedOn}
        </SBHeading>
        <div {...stylex.props(styles.outer)}>
          <div {...stylex.props(styles.inner)}>
            <div {...stylex.props(styles.meta)}>
              <div {...stylex.props(styles.byline)}>
                {avatarUrl ? (
                  <SBImage
                    alt={t(`authors.${kebabCase(authorEnum)}`)}
                    aria-hidden="true"
                    height={48}
                    src={avatarUrl}
                    style={styles.avatar}
                    width={48}
                  />
                ) : null}
                <SBHeading level={HeadingLevel.H2} style={styles.author}>
                  {t("blog.byline", {
                    author: t(`authors.${kebabCase(authorEnum)}`),
                  })}
                </SBHeading>
              </div>
              <div {...stylex.props(styles.shareButtons)}>
                <FacebookShareButton
                  {...stylex.props(styles.shareButton)}
                  url={`${SHARE_BASE_URL}${slug}`}
                >
                  <span
                    {...stylex.props(
                      auto,
                      styles.social,
                      styles.socialFacebook,
                    )}
                  >
                    {t("article.share", {
                      platform: "Facebook",
                    })}
                  </span>
                </FacebookShareButton>
                <TwitterShareButton
                  {...stylex.props(styles.shareButton)}
                  url={`${SHARE_BASE_URL}${slug}`}
                >
                  <span {...stylex.props(auto, styles.social, styles.socialX)}>
                    {t("article.share", {
                      platform: "X",
                    })}
                  </span>
                </TwitterShareButton>
                <SBLink
                  external={true}
                  href="https://www.instagram.com/shortboxed/"
                  style={styles.shareButton}
                >
                  <span
                    {...stylex.props(
                      auto,
                      styles.social,
                      styles.socialInstagram,
                    )}
                  >
                    {t("article.share", {
                      platform: "Instagram",
                    })}
                  </span>
                </SBLink>
              </div>
            </div>
            <div {...stylex.props(styles.image)}>
              <SBImage
                alt={imageAlt ?? title}
                aria-hidden={imageAlt == null}
                src={image}
                width="100%"
              />
            </div>
            {body ? <SBMarkdownRenderer markdown={body} /> : null}
          </div>
        </div>
      </article>
      {queryKey ? <ArticlesRecent notId={id} queryKey={queryKey} /> : null}
    </>
  );
};

const styles = stylex.create({
  article: {
    marginBottom: 96,
  },
  author: {
    fontSize: 24,
  },
  avatar: {
    borderRadius: 32,
    display: "block",
    marginRight: 12,
  },
  byline: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  date: {
    fontSize: 24,
    marginBottom: 96,
    marginTop: 32,
    textAlign: "center",
  },
  heading: {
    marginTop: 96,
    textAlign: "center",
  },
  image: {
    display: "block",
    marginBottom: 32,
  },
  inner: {
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: "50%",
    },
  },
  meta: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  outer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  shareButton: {
    display: "block",
    marginLeft: 32,
  },
  shareButtons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  social: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "block",
    height: 24,
    overflow: "hidden",
    textIndent: -99999,
    width: 24,
  },
  socialFacebook: {
    backgroundImage: colors.socialFacebook,
  },
  socialInstagram: {
    backgroundImage: colors.socialInstagram,
  },
  socialX: {
    backgroundImage: colors.socialX,
  },
});

export default ArticleDetailView;
